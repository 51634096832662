import React from "react";
import { useProduct } from "../../hooks/useProduct";

const Logo: React.FC = () => {
  const productHandler = useProduct();
  const LogoIcon = productHandler?.logo;
  return <LogoIcon />;
};

export default Logo;
