import { AppDispatch } from "../../store/store";
import { getSpiltFirstAndLastName } from "../../helpers/utils";
import { generateCelloTokenThunk } from "../../store/slices/referral.slice";
import { isPreprod, isProduction } from "../../helpers/env.helper";

const getCelloScriptSrc = (): string => {
  if (isProduction() || isPreprod()) {
    return 'https://assets.cello.so/app/latest/cello.js';
  } else {
    return 'https://assets.sandbox.cello.so/app/latest/cello.js';
  }
};

const loadCelloScript = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.cello) {
      resolve(); // If Cello is already loaded, resolve immediately
      return;
    }

    const script = document.createElement("script");
    script.type = "module";
    script.src = getCelloScriptSrc();
    script.async = true;

    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Cello script failed to load"));

    document.head.appendChild(script);
  });
};

// Helper to initialize Cello
export const initializeCello = async (
  session: { name: string; email: string },
  productId: string,
  token: string
): Promise<void> => {
  try {
    // Load the Cello script
    await loadCelloScript();

    // Initialize Cello after script is loaded
    window.cello = window.cello || { cmd: [] };

    window.cello.cmd.push(async function (cello) {
      const nameSplit = getSpiltFirstAndLastName(session.name || "");
      const firstName = nameSplit ? nameSplit.firstName : "";
      const lastName = nameSplit ? nameSplit.lastName : "";
      try {
        await cello.boot({
          productId,
          token,
          language: "en",
          productUserDetails: {
            firstName,
            lastName,
            fullName: session.name,
            email: session.email,
          },
        });
      } catch (error) {
        console.error("Failed to boot Cello:", error);
      }
    });
  } catch (error) {
    console.error("Failed to load Cello script:", error);
  }
};

export const initializeCelloIntegration = async (
  session: { name: string; email: string; token: string },
  dispatch: AppDispatch
): Promise<void> => {
  try {
    const response = await (
      dispatch(generateCelloTokenThunk({ token: session.token })) as any
    ).unwrap();

    const { productId, token } = response;

    // Initialize Cello with the session's token and the token from response
    await initializeCello(session, productId, token);
  } catch (error) {
    console.error("Error initializing Cello", error);
  }
};
