import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { userSelector } from "../../store/selectors";
import { initializeCelloIntegration } from "./cello";

const Cello: React.FC = () => {
  const user = useSelector(userSelector);
  const { name, alternateEmail, token } = user;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (token) {
      initializeCelloIntegration(
        { name, email: alternateEmail, token },
        dispatch
      );
    }
  }, [token]);

  return null;
};

export default Cello;
