import {
  LAMBDA_FUNCTIONS,
  LAMBDA_PATH,
  SERVICE_TYPES,
} from "../../helpers/constants";
import { getConfig } from "../../helpers/utils";
import { TSiteResourcesPayload } from "../../types/site-resources.type";
import { TSiteVisitorData } from "../../types/visitor.type";
import { generateCrid } from "../../utils/api.utils";
import apiManager from "../axiosConfig";

const { env } = getConfig();
const LAMBDA_ENTITY = "neo";

export const saveSiteVisitorData = async (
  payload: TSiteVisitorData,
  params = {}
) => {
  return apiManager.post(
    SERVICE_TYPES.LAMBDA[env as keyof typeof SERVICE_TYPES.LAMBDA],
    LAMBDA_PATH[env as keyof typeof LAMBDA_PATH],
    {
      payload: { ...payload, crid: generateCrid('save_data') },
      params: {
        entity: LAMBDA_ENTITY,
        env,
        functionName: LAMBDA_FUNCTIONS.SAVE_SITE_VISITOR_DATA,
        ...params,
      },
    }
  );
};

export const transferSiteVisitorData = async (payload: any, params = {}) => {
  return apiManager.post(
    SERVICE_TYPES.LAMBDA[env as keyof typeof SERVICE_TYPES.LAMBDA],
    LAMBDA_PATH[env as keyof typeof LAMBDA_PATH],
    {
      payload: { ...payload, crid: generateCrid('transfer_data') },
      params: {
        entity: LAMBDA_ENTITY,
        env,
        functionName: LAMBDA_FUNCTIONS.TRANSFER_SITE_VISITOR_DATA,
        ...params,
      },
    },
    { maxRetries: 1 }
  );
};

export const getUnauthSiteResources = async (payload: TSiteResourcesPayload, params = {}) => {
  return apiManager.post(
    SERVICE_TYPES.LAMBDA[env as keyof typeof SERVICE_TYPES.LAMBDA],
    LAMBDA_PATH[env as keyof typeof LAMBDA_PATH],
    {
      payload: { ...payload, crid: generateCrid('site_industries') },
      params: {
        entity: LAMBDA_ENTITY,
        env,
        functionName: LAMBDA_FUNCTIONS.GET_UNAUTH_SITE_RESOURCES,
        ...params,
      },
    },
    { maxRetries: 3 }
  );
};

// const url = "https://flockmail-backend.flock-staging.com/neo/visitor/data";
// const headers = {
//   "Content-Type": "application/json",
// };

// export const saveSiteVisitorData = async (payload: TSiteVisitorData) => {
//     const response = await fetch(url, {
//         method: "POST",
//         body: JSON.stringify({...payload, crid: 'transfer_data'}),
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       const data = await response.json();
//         return data;
// }
