export const SENTRY_DSN =
  "https://4f178b1a492b4253b6107ce18c952df0@sentry.eks.ops.titan.email/59";

export const HOTJAR_ID = 2304920;
export const HOTJAR_SNIPPET_VERSION = 6;
export const GTM_ID = "GTM-NMVB3JR";
export const INTERCOM_APP_ID = "h9s78srf";
export const ZENDESK_KEY = "48551abe-eec0-4dda-94d2-f3a48a1e36cb";
export const CJ_COOKIE_NAME = "cje";

export const AMPLITUDE_API_KEYS = {
  STAGING: "22de5bc18e126b6bd99c376bbce148f3",
  PREPROD: "1bba6e04e641cc3d47d95792cc7988a6",
  PROD: "1bba6e04e641cc3d47d95792cc7988a6",
};

// hashed partner id
export const NEO_PARTNER_ID = {
  STAGING: "rXBIpBV5n268kQc0Tu2nwCGAsoCM7Mc8",
  PREPROD: "38d97bf09ad31e4dc0304c799ea51731",
  PROD: "38d97bf09ad31e4dc0304c799ea51731",
};
export const NEO_PARTNER_ID_NUMBER = {
  STAGING: 71,
  PREPROD: 54,
  PROD: 54,
};

export const MEDUSA_ENDPOINT = {
  STAGING: "https://flockmail-backend.flock-staging.com/m/",
  PREPROD: "https://api.flockmail.com/m/",
  PROD: "https://api.flockmail.com/m/",
};

export const BLL_ENDPOINT = {
  STAGING: "https://flockmail-bll.flock-staging.com/",
  PREPROD: "https://bll.titan.email/",
  PROD: "https://bll.titan.email/",
};

export const STATIC_ENDPOINT = {
  STAGING: "https://static.flock.co/meta-staging/plan/feature/config",
  PREPROD: "https://static.flock.co/meta/plan/feature/config",
  PROD: "https://static.flock.co/meta/plan/feature/config",
};

// old lambda endpoint
// export const LAMBDA_ENDPOINT =
// "https://jxbkby1ysb.execute-api.us-east-1.amazonaws.com/";

export const LAMBDA_ENDPOINT = {
  STAGING: "https://i3wzr2alj3.execute-api.us-east-1.amazonaws.com/",
  PREPROD: "https://6o1h5p9ju0.execute-api.us-east-1.amazonaws.com/",
  PROD: "https://vp3gsfjyp9.execute-api.us-east-1.amazonaws.com/",
};

export const LAMBDA_PATH = {
  STAGING: "titan-lambda-staging",
  PREPROD: "titan-lambda-preprod/titan-lambda-preprod",
  PROD: "titan-lambda-prod",
};

export const LAMBDA_FUNCTIONS = {
  // E5
  VERIFY_EMAIL: "verifyEmail",
  CREATE_CUSTOMER: "createMailCustomer",
  INIT_ADD_CARD: "initAddCard",
  INITIATE_PURCHASE: "initiatePurchase",
  COMPLETE_PURCHASE: "completePurchase",
  CUSTOMER_LOGIN: "customerLogin",
  GET_ACTIVE_ORDERS: "getActiveOrders",
  CALCULATE_CHARGE: "calculateCharge",
  GET_BILLING_ADDRESS: "getBillingAddress",
  SET_BILLING_ADDRESS: "setBillingAddress",
  CHECK_MAIL_ORDER_AVAILABILITY: "checkMailOrderAvailability",
  CHECK_DOMAIN_REGISTRATION: "checkDomainRegistration",
  GET_SAVED_CARD_DETAILS: "getCardDetails",
  GENERATE_CP_AUTO_LOGIN_TOKEN: "generateCpAutoLoginToken",
  GET_ALL_SET_PLANS: "getAllSetPlans",
  CHECK_EMAIL_AVAILABILITY: "checkNeoEmailAvailability",
  CREATE_BULK_EMAIL: "createBulkEmail",
  AUTO_LOGIN: "customerAutoLogin",
  GET_PROMO_DETAILS: "getPromoDetails",
  SAVE_SITE_VISITOR_DATA: "saveSiteVisitorData",
  TRANSFER_SITE_VISITOR_DATA: "transferSiteVisitorData",
  GET_UNAUTH_SITE_RESOURCES: "getUnauthSiteResources",
  VERIFY_CF_TURNSTILE_TOKEN: "verifyCloudflareTurnstileToken",
  GET_WEBFLOW_USER_DETAILS: "getWebflowUserDetails",
  GENERATE_CELLO_TOKEN: "generateCelloToken",
};

export const URLS = {
  NEO_HOME_PAGE_URL: "https://www.neo.space/",
  WEBMAIL_URL: {
    STAGING: "https://neomail-staging.riva.co/",
    PREPROD: "https://neomail-preprod.riva.co/",
    PROD: "https://app.neo.space/",
  },
  NEO_SITE_URL: {
    STAGING: "https://neomail-staging.riva.co/site/",
    PREPROD: "https://neomail-preprod.riva.co/site/",
    PROD: "https://app.neo.space/site/",
  },
  ADMIN_PANEL_BASE_URL: {
    STAGING: "https://admin-staging.neo.space",
    PREPROD: "https://admin-preprod.neo.space",
    PROD: "https://admin.neo.space",
  },
  SIGNUP_APP_BASE_URL: {
    STAGING: "https://join-staging.neo.space",
    PREPROD: "https://join.neo.space",
    PROD: "https://join.neo.space",
  },
  MAIL_CLIENT_BASE_URL: {
    STAGING: "https://neomail-staging.riva.co/mail",
    PREPROD: "https://neomail-preprod.riva.co/mail",
    PROD: "https://app.neo.space/mail",
  },
};

export const PAGE_PATHS = {
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  ADD_MAILBOX: "/add-mailbox",
  FORGOT_PASSWORD: "/forgot-password",
  PLANS: "/select-plan",
  BILLING: "/billing",
  ALL_DONE: "/all-done",
  PREVIEW_SITE: "/preview-site",
  GET_STARTED: "/get-started",
  GET_STARTED_V2: "/get-started-now",
  HAVE_DOMAIN: "/have-domain",
  GET_DOMAIN: "/get-domain",
  ADMIN_LOGIN_INFO: "/admin-login-info",
  ADD_TEAM_MAILBOX: "/add-team-mailboxes",
  ADD_GENERIC_MAILBOX: "/add-generic-mailboxes",
  PERSONA: "/persona",
  REFER: "/refer",

  // misc routes
  RESTRICTED_REGION: "/restricted-region",

  // site routes
  INDUSTRY: "/site/industry",
  YOUR_IDEA: "/site/your-idea",
  SITE_SIGN_IN: "/site/sign-in",
  SITE_SIGN_UP: "/site/sign-up",
  SITE_GET_STARTED: "/site/get-started",
  SITE_GET_STARTED_V2: "/site/get-started-now",
  SITE_HAVE_DOMAIN: "/site/have-domain",
  SITE_GET_DOMAIN: "/site/get-domain",
  SITE_ADD_MAILBOX: "/site/add-mailbox",
  SITE_BILLING: "/site/billing",
  SITE_PLANS: "/site/select-plan",
  SITE_DOMAIN_SETUP: "/site/domain-selection",

  // integrations
  WEBFLOW: "/integrations/webflow",
};

const {
  SIGN_UP,
  SIGN_IN,
  ADD_MAILBOX,
  FORGOT_PASSWORD,
  PLANS,
  BILLING,
  ALL_DONE,
  PREVIEW_SITE,
  GET_STARTED,
  HAVE_DOMAIN,
  GET_DOMAIN,
  ADMIN_LOGIN_INFO,
  ADD_TEAM_MAILBOX,
  ADD_GENERIC_MAILBOX,
  PERSONA,

  // site routes
  INDUSTRY,
  YOUR_IDEA,
  SITE_SIGN_IN,
  SITE_SIGN_UP,
  SITE_GET_STARTED,
  SITE_HAVE_DOMAIN,
  SITE_GET_DOMAIN,
  SITE_ADD_MAILBOX,
  SITE_BILLING,
  SITE_DOMAIN_SETUP,
} = PAGE_PATHS;

export const CURRENT_PAGE_VIEWED = {
  [SIGN_UP]: "Create customer account",
  [SIGN_IN]: "Sign in to customer account",
  [ADD_MAILBOX]: "Add mailbox",
  [FORGOT_PASSWORD]: "Forgot password",
  [PLANS]: "Select plan",
  [BILLING]: "Checkout",
  [ALL_DONE]: "All done",
  [PERSONA]: "Persona",
  [PREVIEW_SITE]: "Preview site",
  [GET_STARTED]: "Get started",
  [HAVE_DOMAIN]: "Have domain",
  [GET_DOMAIN]: "Get domain",
  [ADMIN_LOGIN_INFO]: "Admin panel login info mobile",
  [ADD_TEAM_MAILBOX]: "Add team mailboxes",
  [ADD_GENERIC_MAILBOX]: "Add generic mailboxes",

  [INDUSTRY]: "Industry",
  [YOUR_IDEA]: "Your idea",
  [SITE_SIGN_IN]: "Site Sign in to customer account",
  [SITE_SIGN_UP]: "Site Create customer account",
  [SITE_GET_STARTED]: "Site Get started",
  [SITE_HAVE_DOMAIN]: "Site Have domain",
  [SITE_GET_DOMAIN]: "Site Get domain",
  [SITE_ADD_MAILBOX]: "Site Add mailbox",
  [SITE_BILLING]: "Site Checkout",
  [SITE_DOMAIN_SETUP]: "Site Domain setup",
};

export const HEADER_TYPES = {
  SIGN_UP: "sign-up",
  SIGN_IN: "sign-in",
  ADD_MAILBOX: "add-mailbox",
  PLANS: "plans",
  SITE_PLANS: "site-plans",
  BILLING_CUSTOM_DOMAIN: "billing-custom",
  BILLING_CO_SITE_TRIAL: "billing-co-site-trial",
  BILLING: "billing",
  PREVIEW_SITE: "preview-site",
  GET_STARTED: "get-started",
  HAVE_DOMAIN: "have-domain",
  GET_DOMAIN: "get-domain",
  ADD_TEAM_MAILBOX: "team-add-mailbox",
  ADD_GENERIC_MAILBOX: "general-add-mailbox",
  INDUSTRY: "industry",
  YOUR_IDEA: "your-idea",
};

export const STRIPE_KEYS = {
  PROD: "pk_live_UCgFiuZoBEszAPxWJDr6DEyN00E5eQGOXH",
  PREPROD: "pk_live_UCgFiuZoBEszAPxWJDr6DEyN00E5eQGOXH",
  STAGING: "pk_test_6V9AYe1IudKEpa8jYJIWJCfz003MCM5dwL",
  UAT: "pk_test_6V9AYe1IudKEpa8jYJIWJCfz003MCM5dwL",
};

export const SERVICE_TYPES = {
  LAMBDA: {
    STAGING: LAMBDA_ENDPOINT.STAGING,
    PREPROD: LAMBDA_ENDPOINT.PREPROD,
    PROD: LAMBDA_ENDPOINT.PROD,
  },
  MEDUSA: {
    STAGING: MEDUSA_ENDPOINT.STAGING,
    PREPROD: MEDUSA_ENDPOINT.PREPROD,
    PROD: MEDUSA_ENDPOINT.PROD,
  },
  BLL: {
    STAGING: BLL_ENDPOINT.STAGING,
    PREPROD: BLL_ENDPOINT.PREPROD,
    PROD: BLL_ENDPOINT.PROD,
  },
  STATIC: {
    STAGING: STATIC_ENDPOINT.STAGING,
    PREPROD: STATIC_ENDPOINT.PREPROD,
    PROD: STATIC_ENDPOINT.PROD,
  },
};

export const ANALYTICS_EVENTS = {
  // E5 events name
  DOMAIN_AVAILABILITY_CHECKED: "domain_availability_checked",
  HAS_DOMAIN_CHECKED: "has_domain_checked",
  GET_STARTED_VIEWED: "get_started_viewed",
  DOMAIN_SELECTED: "domain_selected",
  BUY_DOMAIN_CLICKED: "buy_domain_clicked",
  REGISTRAR_NAME_CLICKED: "registrar_name_clicked",
  CUSTOMER_ACCOUNT_FORGOT_PASSWORD_CLICKED:
    "customer_account_forgot_password_clicked",
  CUSTOMER_ACCOUNT_SIGNED_IN: "customer_account_signed_in",
  CUSTOMER_ACCOUNT_SIGNED_IN_FAILED: "customer_account_sign_in_failed",
  CUSTOMER_ACCOUNT_CREATED: "customer_account_created",
  CUSTOMER_EMAIL_CHECKED_FAILED: "customer_email_check_failed",
  CUSTOMER_PASSWORD_CHECKED_FAILED: "customer_password_check_failed",
  CUSTOMER_CAPTCHA_VERIFICATION_FAILED: "customer_captcha_verification_failed",
  CUSTOMER_ACCOUNT_SIGNED_OUT: "customer_account_signed_out",
  CUSTOMER_ACCOUNT_LINKED: "customer_account_linked",
  FEATURE_CARD_SEEN: "feature_card_seen",

  // Cloudflare Captcha events
  TRAFFIC_CHECK_REQUIRED: "traffic_check_required",
  TRAFFIC_VERIFIED: "traffic_verified",
  TRAFFIC_VERIFICATION_FAILED: "traffic_verification_failed",

  // Misc events
  RESTRICTED_REGION_SHOWN: "restricted_region_shown",

  // Add mailbox Pages
  MAILBOX_ADD_VIEWED: "mailbox_add_viewed",
  MAILBOX_ADDED: "mailbox_added",
  MAILBOX_ADD_FAILED: "mailbox_add_failed",

  TEAM_MAILBOX_ADD_VIEWED: "team_mailbox_add_viewed",
  TEAM_MAILBOX_ADD_CONTINUED: "team_mailbox_add_continued",

  GENERIC_MAILBOX_ADD_VIEWED: "generic_mailbox_add_viewed",
  GENERIC_MAILBOX_ADD_CONTINUED: "generic_mailbox_add_continued",

  MAILBOX_DELETED: "mailbox_deleted",
  NEO_MAILBOX_CREATION_FAILED: "neo_mailbox_creation_failed",

  //Preview site page
  NEO_SITE_PREVIEWED: "neo_site_previewed",

  //Plans Page
  PLAN_SELECTED: "plan_selected",
  LARGE_TEAM_LINK_CLICKED: "large_team_link_clicked",
  PLAN_BILLING_TAB_SWITCHED: "plan_billing_tab_switched",

  //Billing page
  ORDER_SUMMARY_REVIEWED: "order_summary_reviewed",
  CUSTOMER_BILLING_DETAILS_ENTERED: "customer_billing_details_entered",
  CUSTOMER_PAYMENT_CARD_SUBMITTED: "customer_payment_card_submitted",
  CUSTOMER_PAYMENT_CARD_VERIFIED: "customer_payment_card_verified",
  CUSTOMER_PAYMENT_CARD_VERIFICATION_FAILED:
    "customer_payment_card_verification_failed",
  CUSTOMER_PAYMENT_COMPLETED: "customer_payment_completed",
  CUSTOMER_PAYMENT_FAILED: "customer_payment_failed",
  CUSTOMER_ORDER_CREATED: "customer_order_created",
  ORDER_CREATED: "order_created",
  PAYMENT_COMPLETED: "payment_completed",

  MAILBOX_PLAN_REMOVE_CLICKED: "mailbox_plan_remove_clicked",
  MAILBOX_PLAN_REMOVED: "mailbox_plan_removed",

  BILLING_CYCLE_DROPDOWN_CLICKED: "billing_cycle_dropdown_clicked",
  PLAN_BILLING_CYCLE_UPDATED: "plan_billing_cycle_updated",
  ORDER_SUMMARY_BACK_CLICKED: "order_summary_back_clicked",

  //Persona page
  CUSTOMER_PERSONA_SUBMITTED: "customer_persona_submitted",
  NEO_PERSONA_SUBMITTED: "neo_persona_submitted",

  PURCHASE_COMPLETED_REDIRECTION: "purchase_completed_redirection",
  PURCHASE_COMPLETED_DOWNLOAD_APP_CLICKED:
    "purchase_completed_download_app_clicked",
  WEBSITE_PAGE_VIEWED: "website_page_viewed",

  // Promo
  CUSTOMER_PROMO_CODE_FAILED: "customer_promo_code_failed",

  // Exit intent
  EXIT_POPUP_VIEWED: "exit_popup_viewed",
  EXIT_POPUP_EMAIL_SUBMITTED: "exit_popup_email_submitted",
  EXIT_POPUP_CLICKED: "exit_popup_clicked",
  EXIT_POPUP_REMIND_LATER_CLICKED: "exit_popup_remind_later_clicked",
  EXIT_POPUP_CLOSED: "exit_popup_closed",
  EXIT_CONFIRMATION_SEEN: "exit_confirmation_seen",
  EXIT_CONFIRMATION_CLOSE_CLICKED: "exit_confirmation_close_clicked",
  EXIT_CONFIRMATION_CLICKED: "exit_confirmation_clicked",
  TRIGGER_EMAIL: "trigger_email",

  //VWO
  VWO_LOADED: "vwo_loaded",

  //bundle
  CUSTOMER_BUNDLE_CREATED: "customer_bundle_created",

  // business site offering onboard
  BUSINESS_IDEA_PAGE_VIEWED: "business_idea_page_viewed",
  BUSINESS_IDEA_SUBMITTED: "business_idea_submitted",

  BUSINESS_INDUSTRY_PAGE_VIEWED: "business_industry_page_viewed",
  BUSINESS_INDUSTRY_SUBMITTED: "business_industry_submitted",

  ALREADY_HAVE_DOMAIN_CLICKED: "already_have_domain_clicked",
  INVITEE_REWARD_MODAL_SEEN: "invitee_reward_modal_seen",
  REFERRAL_PAGE_VIEWED: "referral_page_viewed"
};

export const APP_STORE_CONSTANTS = {
  ANDROID: {
    NAME: "Google Play Store",
    URL: "https://play.google.com/store/apps/details?id=space.neo.app&referrer=",
  },
  IOS: {
    NAME: "Apple App Store",
    URL: "https://apps.apple.com/in/app/neo-mail/id1556396060",
  },
};

export const ENVIRONMENTS = {
  PROD: "production",
  PREPROD: "preprod",
  STAGING: "staging",
};

export const MINIMUM_PASSWORD_LENGTH = 8;
export const MAXIMUM_PASSWORD_LENGTH = 100;

export const INPUT_ERROR_MESSAGE = {
  PLEASE_ENTER_YOUR_FULL_NAME: "Please enter your full name",
  PLEASE_ENTER_VALID_USERNAME: "Please enter a valid username",
  PLEASE_ENTER_VALID_EMAIL: "Please enter a valid email address",
  PLEASE_ENTER_VALID_PASSWORD: "Please enter a valid password",
  PASSWORD_ERROR: `The password should be of min. ${MINIMUM_PASSWORD_LENGTH} characters and a combination of letters, numbers, and symbols (like @ $ # %)`,
  MAILBOX_ALREADY_ADDED_WITH_THIS_USERNAME:
    "Mailbox with this username has already been added. Enter another username",
};

export const BILLING_CYCLE_KEYS = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
  TWO_YEARLY: "two_yearly",
  FOUR_YEARLY: "four_yearly",
  QUARTERLY: "quarterly",
} as const;

export const BILLING_CYCLE_SELECTION_LABELS = {
  [BILLING_CYCLE_KEYS.MONTHLY]: "Monthly",
  [BILLING_CYCLE_KEYS.QUARTERLY]: "Quarterly",
  [BILLING_CYCLE_KEYS.YEARLY]: "Yearly",
  [BILLING_CYCLE_KEYS.TWO_YEARLY]: "2 Yearly",
  [BILLING_CYCLE_KEYS.FOUR_YEARLY]: "4 Yearly",
} as const;

export const PLAN_SETS = {
  DEFAULT: "default",
  CHANGE_BILLING_CYCLE_DISCOUNT: "change_billing_cycle_discount",
};

export const BILLING_CYCLE_DURATIONS = {
  [BILLING_CYCLE_KEYS.YEARLY]: "Yearly",
  [BILLING_CYCLE_KEYS.MONTHLY]: "Monthly",
  [BILLING_CYCLE_KEYS.TWO_YEARLY]: "Two Yearly",
  [BILLING_CYCLE_KEYS.FOUR_YEARLY]: "Four Yearly",
  [BILLING_CYCLE_KEYS.QUARTERLY]: "Quarterly",
};

export const BILLING_CYCLE_PERIOD_UNITS = {
  [BILLING_CYCLE_KEYS.MONTHLY]: "month",
  [BILLING_CYCLE_KEYS.QUARTERLY]: "quarter",
  [BILLING_CYCLE_KEYS.YEARLY]: "year",
  [BILLING_CYCLE_KEYS.TWO_YEARLY]: "2 years",
  [BILLING_CYCLE_KEYS.FOUR_YEARLY]: "4 years",
} as const;

export const BILLING_CYCLE_PERIOD_VERBOSE_UNITS = {
  [BILLING_CYCLE_KEYS.MONTHLY]: "month",
  [BILLING_CYCLE_KEYS.QUARTERLY]: "quarter",
  [BILLING_CYCLE_KEYS.YEARLY]: "year",
  [BILLING_CYCLE_KEYS.TWO_YEARLY]: "two years",
  [BILLING_CYCLE_KEYS.FOUR_YEARLY]: "four years",
} as const;

export const BILLING_CYCLE_DISPLAY_LABELS = {
  [BILLING_CYCLE_KEYS.MONTHLY]: "Monthly",
  [BILLING_CYCLE_KEYS.QUARTERLY]: "Quarterly",
  [BILLING_CYCLE_KEYS.YEARLY]: "Yearly",
  [BILLING_CYCLE_KEYS.TWO_YEARLY]: "Two Yearly",
  [BILLING_CYCLE_KEYS.FOUR_YEARLY]: "Four Yearly",
} as const;

export const NUMBER_OF_MONTHS = {
  yearly: 12,
  monthly: 1,
  two_yearly: 24,
  four_yearly: 48,
  quarterly: 3,
};

export const NEO_E5_DOMAINS = {
  STAGING: "costaging.site",
  PREPROD: "copreprod.site",
  PROD: "co.site",
};

//279 pro, 280 premium

export const COMPONENTS_NAME = {
  ORDER_SUMMARY: "OrderSummary",
  BILLING_DETAILS: "BillingDetails",
  CARD_DETAILS: "CardDetails",
};

export const DOMAIN_PARTNERS = {
  GO_DADDY: {
    NAME: "GoDaddy",
    URL: "https://www.godaddy.com/en-in",
  },
  NAMECHEAP: {
    NAME: "NameCheap",
    URL: "https://www.namecheap.com",
  },
};

export const sourceHookMapping = {
  neoAvatarSignIn: "Neo Avatar Sign in",
  purchaseFlow: "Purchase flow",
  neoAdminPanel: "Neo Admin Panel",
} as Record<string, string>;

export const validDomainRegex =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

export const specialCharsRegex = /[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~\\]/;

export const emailRegex =
  /^[a-zA-Z0-9_-]+([.]?[a-zA-Z0-9_-])*@\w+([.-]?\w+)*(\.\w{2,18})+$/;

export const gstinRegex = /^[a-zA-Z0-9]{15}$/;

export const DOMAIN_TYPE = {
  ALREADY_HAVE_DOMAIN: "Already have a domain",
  DONT_HAVE_DOMAIN: "Don't have a domain",
};

export const LANDINGPAGE_URL = {
  STAGING: "https://staging.neo.space",
  PREPROD: "https://preprod.neo.space",
  PROD: "https://neo.space",
};

export const TIMESTAMPS = {
  THREE_HOURS: 10800000,
  THIRTY_DAYS: 2592000000,
};

export const NEO_OFFERING_EVENT_VALUE = {
  USER_CUSTOM_DOMAIN: "User custom domain",
  CO_DOT_SITE: "co.site",
};

// Redux Store keys
export const LS_STORE_KEY = "neo-e5-dtc";
export const OLD_LS_STORE_KEY = "storeState";
export const LS_PERSIST_STORE_KEY = `persist:${LS_STORE_KEY}`;

export const DOMAIN_ERROR_CODE = {
  INVALID_DOMAIN: "INVALID_DOMAIN",
  SHORT_DOMAIN_NAME: "SHORT_DOMAIN_NAME",
  DISALLOWED_DOMAIN: "DISALLOWED_DOMAIN",
};

export const COOKIE_KEYS = {
  DEVICE_ID: "deviceId",
  HOTJAR_ID: "hotjarId",
  UTM_PARAMS: "utmParams",
  CUSTOMER_ID_COOKIE: "customerId",
  MAIL_VISITOR_EMAIL: "mail_visitor_email",
  SITE_VISITOR_EMAIL: "site_visitor_email",
  MAIL_LAST_EXIT_INTENT_SHOWN_AT: "mail_last_exit_intent_shown_at",
  SITE_LAST_EXIT_INTENT_SHOWN_AT: "site_last_exit_intent_shown_at",
};

export const GA_EVENTS = {
  DOMAIN_AVAILABILITY_CHECKED: "CV-Intent-V2-Custom",
  CUSTOMER_ACCOUNT_CREATED: "Cv_customer_account_created_custom",
  PV_PERSONA: "Pv-Persona",
  SITE_ORDER_CREATED: "Custom_site_order_created",
};

export const WINDOW_NAME = {
  FLOCK_ADMIN: "FlockAdmin",
  FLOCK_MAIL: "FlockMail",
  FLOCK_SITE: "FlockSite",
};

export const SOURCE_OF_MAILBOX_ADDITION = {
  TEAM_MAILBOX: "teamMailbox",
  SUGGESTION_MAILBOX: "suggestionMailbox",
  CUSTOM_MAILBOX: "customMailbox",
};

export const MAXIMUM_MAILBOX_LIMIT_COUNT = 10;

export const MAILBOX_ORDER_LIMIT_ERROR_MSG = {
  CUSTOM_DOMAIN_ERROR: `You can only add upto ${MAXIMUM_MAILBOX_LIMIT_COUNT} mailboxes during free trial`,
  COSITE_DOMAIN_ERROR: `${MAXIMUM_MAILBOX_LIMIT_COUNT} mailboxes added. You can add more later from the Neo Admin Panel`,
};

export const EMAIL_SUGGESTION_LIST = [
  {
    username: "info",
    isSuggestion: true,
    isAdded: false,
    isPopular: true,
    source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX, // source of email addition to the mailbox order list
  },
  {
    username: "support",
    isSuggestion: true,
    isAdded: false,
    isPopular: true,
    source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  },
  {
    username: "admin",
    isSuggestion: true,
    isAdded: false,
    isPopular: false,
    source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  },
  {
    username: "contact",
    isSuggestion: true,
    isAdded: false,
    isPopular: false,
    source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  },
  {
    username: "sales",
    isSuggestion: true,
    isAdded: false,
    isPopular: false,
    source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  },
  // {
  //   username: "hello",
  //   isSuggestion: true,
  //   isAdded: false,
  //   isPopular: false,
  //   source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  // },
  // {
  //   username: "order",
  //   isSuggestion: true,
  //   isAdded: false,
  //   isPopular: false,
  //   source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  // },
  // {
  //   username: "accounts",
  //   isSuggestion: true,
  //   isAdded: false,
  //   isPopular: false,
  //   source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  // },
  // {
  //   username: "jobs",
  //   isSuggestion: true,
  //   isAdded: false,
  //   isPopular: false,
  //   source: SOURCE_OF_MAILBOX_ADDITION.SUGGESTION_MAILBOX,
  // },
];

export const EMAIL_SUGGESTION_USERNAME_LIST = [
  "info",
  "support",
  "admin",
  "contact",
  "sales",
  // "hello",
  // "order",
  // "accounts",
  // "jobs",
];

export const EVENT_MAILBOX_TYPE = {
  SYSTEM_SUGGESTED: "System suggested",
  CUSTOM: "Custom",
};

// Time in seconds i.e 5 second
export const SUCCESS_REDIRECTION_TIME = 5;

export const MAILBOX_PAGE_SOURCE_HOOK = {
  ADMIN_MAILBOX_PAGE: "Admin mailbox page",
  TEAM_MAILBOX_PAGE: "Team member mailbox page",
  GENERIC_MAILBOX_PAGE: "Generic mailbox page",
};

export const TRIAL_DURATIONS_IN_DAYS = {
  CUSTOM_DOMAIN: 15,
  CO_SITE: 15,
};

export const SOURCE_HOOKS = {
  CO_SITE_UPSELL_ON_CUSTOM_DOMAIN_ERROR:
    "co.site upsell on custom domain error",
};

export const PAYMENT_ERROR_IGNORE = [
  "Your card was declined for making repeated attempts too frequently.",
  "ABUSIVE_USER",
  "card_declined",
  "DOMAIN_NAME_NOT_AVAILABLE",
  "invalid_cvc",
  "processing_error",
  "setup_intent_authentication_failure",
  "rate_limit_exceeded",
  "incorrect_number",
  "expired_card",
  "incorrect_cvc",
];

// Do not change this. It is being used in GTM. Make sure to update there as well.
export const OFFERING = {
  COSITE: "co.site",
  CUSTOM_DOMAIN: "custom domain",
};

export const RESTRICTED_COUNTRIES = [
  "CU", // Cuba
  "IR", // Iran
  "KP", // North Korea
  "SY", // Syria
  "KE", // Kenya
];
