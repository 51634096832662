import { PRODUCT_TYPES } from "../../constants/products.constants";
import { resolveIndustryIdentifier } from "../../helpers/industry.helper";
import { getProductFromUrlPath } from "../../helpers/products.helper";
import { getUrlParams } from "../../helpers/utils";
import { TIndustry } from "../../types/industry.type";

export const getSiteBuilderEventData = () => {
  const { siteCategory, industryKey, templateName, bn, bd, subIndustry } =
    getUrlParams();

  return {
    site_category: siteCategory,
    industry: industryKey,
    template_name: templateName,
    business_name: bn,
    business_description: bd,
    sub_industry: subIndustry,
  };
};

export const getCommonSiteProductData = () => {
  const productType = getProductFromUrlPath(window.location.pathname);
  if (productType === PRODUCT_TYPES.SITE) {
    return {
      ...getSiteBuilderEventData(),
    };
  }
  return {}; // Return an empty object if product type is not SITE
};

export const getIndustryEventData = (
  industry: string,
  industries: TIndustry[]
) => {
  const industryData = resolveIndustryIdentifier(industry, industries);
  return {
    industry: industryData.parentIndustryKey
      ? industryData.parentIndustryKey
      : industryData.industryKey || industry,
    sub_industry: industryData.parentIndustryKey ? industryData.industryKey : undefined,
  };
};
