import { PERCENTAGE } from "../constants/promo.constants";
import { Discount } from "../types/global";
import { TPromoCodeDiscounts } from "../types/promo.type";
import { getRoundedDecimal } from "./utils";

export const getDiscount = (discount: Discount, amount: number): number => {
  if (discount?.type === PERCENTAGE) {
    return discount?.value
      ? (getRoundedDecimal(
          Math.max((discount.value / 100) * amount, 0),
          2
        ) as number)
      : 0;
  }
  return 0;
};

export const getVerboseDiscount = (discount: Discount) => {
  if (discount?.type === PERCENTAGE) {
    return discount?.value ? `${discount.value}%` : "";
  }
  return "";
};

export const getDiscountPercentage = (discount: Discount) => {
  if (discount?.type === PERCENTAGE) {
    return discount?.value ? discount.value : 0;
  }
  return 0;
};

export const getAveragedVerboseDiscount = (discounts: TPromoCodeDiscounts) => {
  const siteDiscount = getDiscountPercentage(discounts.site);
  const emailDiscount = getDiscountPercentage(discounts.email_account);
  const domainDiscount = getDiscountPercentage(discounts.domain);
  const averageDiscount = (siteDiscount + emailDiscount + domainDiscount) / 3;
  return `${averageDiscount}%`;
};
