import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import React, { useEffect, useMemo } from "react";
import { PITCH_TYPES } from "../../../constants/exitIntent.constants";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import { appSelector } from "../../../store/selectors";
import { removeTrailingSlashes } from "../../../utils/urlUtils";
import { getDiscountPercentage } from "../../../helpers/discount.helper";
import {
  addUTMAndPromoCodeToUrl,
  onEvent,
  triggerEmailTo,
} from "../../../helpers/exitIntent.helper";
import { useExitIntent } from "../../../hooks/useExitIntent";
import { businessIndustrySelector } from "../../../store/slices/visitor.slice";
import { selectSitePromoCodeDetailsForExitIntent } from "../../../store/slices/site.slice";
import SitePitchModal from "./SitePitchModal";

const getUTMData = (discountPercentage: number) => ({
  utm_source: "site",
  utm_campaign: "site-visitor-exit",
  utm_content: `discount-${discountPercentage}-percent`,
});

const config = {
  [PAGE_PATHS.INDUSTRY]: { pitch: PITCH_TYPES.SITE },
  [PAGE_PATHS.YOUR_IDEA]: { pitch: PITCH_TYPES.SITE },
};

const ExitIntent = () => {
  const loggedIn = useIsLoggedIn();
  const location = useLocation();
  const { search_params } = useSelector(appSelector);
  const industry = useSelector(businessIndustrySelector) || "";
  const { pitch } = useMemo(
    () => config[removeTrailingSlashes(location.pathname)] || {},
    [location]
  );

  const disabled = loggedIn || !pitch;

  const { exitIntent, hideExitIntent, updateVisitorEmail } = useExitIntent({
    disabled,
    key: "site",
  });

  const promoCodeDetails = useSelector(selectSitePromoCodeDetailsForExitIntent);
  const siteDiscount = promoCodeDetails?.discount;

  const discountPercentage = siteDiscount
    ? getDiscountPercentage(siteDiscount)
    : 10;

  useEffect(() => {
    if (!exitIntent) return;
    onEvent(ANALYTICS_EVENTS.EXIT_POPUP_VIEWED, {
      exitIntent,
      pitch,
      location,
      discountPercentage,
    });
  }, [exitIntent]);

  const _triggerEmailTo = (email: string, templateName: string) => {
    triggerEmailTo(email, templateName, {
      promoCodeDetails,
      discountPercentage,
      additionalPayload: {
        industry_name: industry,
        promo_code_id: promoCodeDetails.id,
      },
    });
  };

  const _submitEmail = (email: string) => {
    updateVisitorEmail(email);
    addUTMAndPromoCodeToUrl(
      search_params,
      getUTMData(discountPercentage),
      promoCodeDetails.id
    );

    _triggerEmailTo(email, "site_exit_intent_with_discount");
  };

  const _onEvent = (eventName: string, extraPayload = {}) =>
    onEvent(
      eventName,
      { exitIntent, pitch, location, discountPercentage },
      extraPayload
    );

  if (!pitch || !exitIntent) return null;

  return (
    <SitePitchModal
      isOpen={!!exitIntent}
      onClose={hideExitIntent}
      onSubmit={_submitEmail}
      onEvent={_onEvent}
      discountPercentage={discountPercentage}
    />
  );
};

export default ExitIntent;
