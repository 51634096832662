import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { businessIndustrySelector } from "../../../store/slices/visitor.slice";
import { PAGE_PATHS } from "../../../helpers/constants";

const SiteRouteProtection = ({ children }) => {
  const industry = useSelector(businessIndustrySelector);

  if (!industry) {
    return (
      <Navigate
        to={{
          pathname: PAGE_PATHS.INDUSTRY,
        }}
        replace
      />
    );
  }

  return children;
};

export default SiteRouteProtection;
