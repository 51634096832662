import { checkDomain, showEntri } from "entrijs";
import { DUMMY_DNS_RECORDS, ENTRI_MODAL_LAST_STATUS } from "./const";
import { logger } from "./helpers";

// class to handle entri specific operations
class Entri {
  // in purchase flow we make call to checkDomain
  // in that case we cache the response so that when entri flow is loaded(after purchase complete)
  // we don't have to make another call to entri
  checkDomainResponse = null;

  async checkDomain(domain, config) {
    if (this.checkDomainResponse && this.checkDomainResponse[domain]) {
      return Promise.resolve(this.checkDomainResponse[domain]);
    }

    const configWithDefaultHeaders = {
      dnsRecords: DUMMY_DNS_RECORDS,
      ...config
    };

    const response = await checkDomain(domain, configWithDefaultHeaders);
    logger('checkDomain config', domain, configWithDefaultHeaders);
    logger('checkDomain response', domain, response);

    this.checkDomainResponse = {
      [domain]: response
    };

    return response;
  }

  hasCachedResponse() {
    return !!this.checkDomainResponse;
  }

  isAutomaticSetupComplete(closeEventResponse) {
    return closeEventResponse.lastStatus === ENTRI_MODAL_LAST_STATUS.FINISHED_SUCCESSFULLY;
  }

  showEntri(config) {
    showEntri(config);
  }
}

export const entri = new Entri();
