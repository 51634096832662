import { QueryParam } from "../types/global";
import { store } from "../store/store";
import { appActions } from "../store/actions";
import { addQueryParamsInUrl } from "../utils/urlUtils";
import { redirectToNeoAdminPanel } from "./utils";
import { WINDOW_NAME } from "./constants";

export const addQueryParamsInCurrentUrlAndStore = (
  params: Record<string, QueryParam>
): string => {
  const updatedUrl = addQueryParamsInUrl(window.location.href, params);
  window.history.replaceState("", "", updatedUrl);
  const searchParams = new URL(updatedUrl).search;
  store.dispatch(
    appActions.updateAppParams({ search_params: searchParams })
  );
  return searchParams;
};

export const handleRedirectToNeoAdminPanel = () => {
  window.open(redirectToNeoAdminPanel(), WINDOW_NAME.FLOCK_ADMIN);
};
