// Ant design theme
const themeAntDesign = {
  Input: {
    controlHeight: 56,
    borderRadius: 4,
    fontSize: 16,
    colorBorder: "var(--border-color)",
    colorPrimary: "var(--primary-color)",
    colorPrimaryHover: "var(--primary-color)",
    activeShadow: "0 0 0 0",
    colorText: "var(--primary-text-color)",
  },
};

export default themeAntDesign;
