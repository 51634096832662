export const createBackButtonExitIntentManager = (id: string) => {
  let historyInitialized = false;

  const init = () => {
    if (historyInitialized) return;

    const { href } = window.location;

    window.history.replaceState(
      {
        ...window.history.state,
        isExitIntent: true,
        id,
        unusedButRequired: "unusedButRequired",
      },
      "",
      ""
    );

    window.history.pushState(
      { ...window.history.state, isExitIntent: false },
      "",
      href
    );

    historyInitialized = true;
  };

  const addListener = (
    onExitIntent: () => void,
    disabled: boolean = false,
    canGoBack: () => boolean = () => false
  ) => {
    const handlePopState = (event: PopStateEvent) => {
      const currentState = event.state;

      if (currentState.id !== id) return;
      if (currentState?.isExitIntent) {
        if (disabled || canGoBack?.()) {
          window.history.back();
          return;
        }
        onExitIntent();
      }
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  };

  return {
    init,
    addListener,
  };
};
