export const PERCENTAGE = "percentage" as const;

export const PROMO_ERROR_CODES = {
  PROMO_CODE_EXPIRED: "PromoCodeExpired",
  PROMO_CODE_USED: "PromoCodeUsed",
  PROMO_CODE_NOT_YET_APPLICABLE: "PromoCodeNotYetApplicable",
  PROMO_CODE_INVALID_FOR_MAIL: "PromoCodeInvalidForMail",
  PROMO_CODE_INVALID_FOR_SITE: "PromoCodeInvalidForSite",
  PROMO_CODE_INVALID: "PromoCodeInvalid",
};
