import axios from "axios";
import {
  LAMBDA_FUNCTIONS,
  LAMBDA_PATH,
  SERVICE_TYPES,
} from "../helpers/constants";
import { getConfig } from "../helpers/utils";
import apiManager from "./axiosConfig";

const { env } = getConfig();
const LAMBDA_ENTITY = "neo";

export const verifyEmail = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.VERIFY_EMAIL,
      ...params,
    },
  });
};

export const createCustomer = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CREATE_CUSTOMER,
      ...params,
    },
  });
};

export const visitorSiteData = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.VISITOR_SITE_DATA,
      ...params,
    },
  });
};

// Will accept product type to get plans for that product type
export const getAllSetPlans = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_ALL_SET_PLANS,
      ...params,
    },
  });
};

// Will accept product type to get plan features. Change folder structure to have /mail /site /domain etc in static folder.
export const getPlanFeatureProps = async (locale = "en") => {
  try {
    const response = await axios.get(
      `${SERVICE_TYPES.STATIC[env]}/${locale}.json`,
      {
        headers: { "Content-Type": "application/json" },
        cache: false,
      }
    );
    return response.data;
  } catch (err) {
    console.log("Failed to get plan feature card details", err);
    return null;
  }
};

export const initAddCard = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.INIT_ADD_CARD,
      ...params,
    },
  });
};

export const initiatePurchase = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.INITIATE_PURCHASE,
      ...params,
    },
  });
};

export const completePurchase = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.COMPLETE_PURCHASE,
      ...params,
    },
  });
};

export const customerLogin = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CUSTOMER_LOGIN,
      ...params,
    },
  });
};

export const getActiveOrders = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_ACTIVE_ORDERS,
      ...params,
    },
  });
};

export const calculateCharge = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CALCULATE_CHARGE,
      ...params,
    },
  });
};

export const getBillingAddress = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_BILLING_ADDRESS,
      ...params,
    },
  });
};

export const setBillingAddress = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.SET_BILLING_ADDRESS,
      ...params,
    },
  });
};

export const checkMailOrderAvailability = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CHECK_MAIL_ORDER_AVAILABILITY,
      ...params,
    },
  });
};

export const checkDomainRegistration = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CHECK_DOMAIN_REGISTRATION,
      ...params,
    },
  });
};

export const getSavedCardDetails = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_SAVED_CARD_DETAILS,
      ...params,
    },
  });
};

export const generateCpAutoLoginToken = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GENERATE_CP_AUTO_LOGIN_TOKEN,
      ...params,
    },
  });
};

export const logMedusaAnalytics = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.MEDUSA[env], "addPreAuthEvent", {
    payload,
    params: {
      ...params,
    },
  });
};

export const insertIntoVisitorTable = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.MEDUSA[env], "visitor/insert", {
    payload,
    params: {
      ...params,
    },
  });
};

export const logEntityChange = async (payload, params = {}, headers) => {
  return apiManager.post(SERVICE_TYPES.MEDUSA[env], "entityChange", {
    payload,
    params: {
      ...params,
    },
    headers,
  });
};

export const checkEmailAvailability = async (payload, params = {}, headers) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload: { payload },
    params: {
      ...params,
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CHECK_EMAIL_AVAILABILITY,
    },
  });
};

export const createBulkEmail = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      ...params,
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.CREATE_BULK_EMAIL,
    },
  });
};

// Functions to call BLL api directly
/**
 *  @deprecated
 * Used inside mail box purchase modal so depreciated
 */
export const initPayment = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.BLL[env], "panel/billing/init-payment", {
    payload,
    params: {
      ...params,
    },
    headers: {
      Authorization: payload.token,
    },
  });
};

/**
 *  @deprecated
 * Used inside mail box purchase modal so depreciated
 */
export const processPayment = async (payload, params = {}) => {
  return apiManager.post(
    SERVICE_TYPES.BLL[env],
    "panel/billing/process-payment",
    {
      payload,
      params: {
        ...params,
      },
      headers: {
        Authorization: payload.token,
      },
    }
  );
};

export const autoLogin = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.AUTO_LOGIN,
      ...params,
    },
  });
};

export const getPromoDetails = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_PROMO_DETAILS,
      ...params,
    },
  });
};

export const verifyCloudflareTurnstileToken = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.VERIFY_CF_TURNSTILE_TOKEN,
      ...params,
    },
  });
};

export const getWebflowUserDetails = async (payload, params = {}) => {
  return apiManager.post(SERVICE_TYPES.LAMBDA[env], LAMBDA_PATH[env], {
    payload,
    params: {
      entity: LAMBDA_ENTITY,
      env,
      functionName: LAMBDA_FUNCTIONS.GET_WEBFLOW_USER_DETAILS,
      ...params,
    },
  });
};

export const getUserIPDetails = async () => {
  try {
    return await axios.get(
      `${SERVICE_TYPES.BLL[env]}internal/ip-to-country`,
      {}
    );
  } catch (err) {
    console.error("Failed to get user IP details", err);
    return null;
  }
};
