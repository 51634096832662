import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../types/store.type";
import { OFFERING } from "../../helpers/constants";
import { LOGOUT } from "../actions/actionsConstants";
import { TPlan } from "../../types/plan.type";
import { isFreePlan } from "../../helpers/plans.helper";
import {
  TPromoCodeDetails,
  TPromoCodeErrorDetails,
} from "../../types/promo.type";
import { SITE_PROMO_CODE_DETAILS } from "../../constants/exitIntent.constants";
import { createDomainSlice } from "./domain.slice";
import { createPlanSlice } from "./plan.slice";
import { createMailboxSlice } from "./mailbox.slice";
import { createPlansSlice } from "./plans.slice";

const siteDomainSlice = createDomainSlice("site");
const sitePlanSlice = createPlanSlice("site");
const siteMailboxSlice = createMailboxSlice("site");
const sitePlansSlice = createPlansSlice("site");

const combinedSiteReducer = combineReducers({
  domain: siteDomainSlice.reducer,
  plan: sitePlanSlice.reducer,
  mailboxToPurchase: siteMailboxSlice.reducer,
  plans: sitePlansSlice.reducer,
});

// Define the initial state for the site slice
const initialSiteState = combinedSiteReducer(undefined, { type: "" });

const siteSlice = createSlice({
  name: "site",
  initialState: initialSiteState,
  reducers: {
    // Additional site-specific reducers if needed
  },
  extraReducers: (builder) => {
    // Using extraReducers to handle the logout action, as it is defined in another slice
    builder.addCase(LOGOUT, () => {
      // Reset mail state to initial state on logout
      return initialSiteState;
    });

    builder.addDefaultCase(combinedSiteReducer); // Combine the sub-slice reducers
  },
});

export const {
  setDomain: setSiteDomain,
  setDomainPricing: setSiteDomainPricing,
  setDomainAttrs: setSiteDomainAttrs,
  resetDomainState: resetSiteDomainState,
} = siteDomainSlice.actions;
export const {
  setPlan: setSitePlan,
  setPlanDuration: setSitePlanDuration,
  setPlanId: setSitePlanId,
  resetPlan: resetSitePlan,
} = sitePlanSlice.actions;
export const { setPlans: setSitePlans } = sitePlansSlice.actions;
export const {
  setMailbox: setSiteMailbox,
  setNoOfAdditionalMailboxes: setSiteNoOfAdditionalMailboxes,
  setAdminMailbox: setSiteAdminMailbox,
  resetMailboxState: resetSiteMailboxState,
} = siteMailboxSlice.actions;

export default siteSlice.reducer;

export const selectSiteDomain = (state: RootState) => state.site.domain;
export const selectSiteDomainPricing = (state: RootState) =>
  state.site.domain.pricing;
export const selectSiteDomainAttrs = (state: RootState) =>
  state.site.domain.attrs;
export const selectSitePlan = (state: RootState) => state.site.plan;
export const selectSitePlans = (state: RootState) => state.site.plans;
export const selectSitePlanDetails = createSelector(
  selectSitePlans,
  selectSitePlan,
  (plans, { id }) => {
    return plans.find((plan) => plan.id === id);
  }
);
export const selectSitePlanDuration = (state: RootState) =>
  state.site.plan.duration;
export const selectSiteNoOfAdditionalMailboxes = (state: RootState) =>
  state.site.mailboxToPurchase.noOfAdditionalMailboxes;
export const selectSiteAdminMailbox = (state: RootState) =>
  state.site.mailboxToPurchase.adminMailbox;
export const selectSitePurchaseDomain = (state: RootState) => {
  const domain = state.site.domain;
  return domain.offering === OFFERING.COSITE
    ? domain.coSiteDomain
    : domain.customDomain;
};
export const isSiteDomainCustom = (state: RootState): boolean => {
  const { offering } = selectSiteDomain(state);
  return offering === OFFERING.CUSTOM_DOMAIN;
};

export const selectSiteFreePlan = createSelector(
  selectSitePlans,
  (plans: TPlan[]) => {
    return plans.find(isFreePlan) || plans[0];
  }
);

export const selectSitePromoDetails = (
  state: RootState
): TPromoCodeDetails | null => {
  const promoCodeDetails = state.promo.promoCodeDetails;
  if (!promoCodeDetails) {
    return null;
  }

  const { config, ...rest } = promoCodeDetails;

  // If the promo contains a site discount, return the details.
  if (config.discounts.site) {
    return {
      ...rest,
      discount: config.discounts.site,
    } as TPromoCodeDetails;
  }

  return null;
};

export const selectSiteApplicablePromoDetails = (state: RootState) => {
  const promoCodeDetails = state.promo.promoCodeDetails;
  if (!promoCodeDetails) {
    return null;
  }

  const { config, ...rest } = promoCodeDetails;

  // If the promo contains a site discount, return the details.
  if (config.discounts.site) {
    return {
      ...rest,
      discounts: config.discounts,
    };
  }

  return null;
};

export const selectSitePromoErrorDetails = (state: RootState) => {
  if (state.promo.promoCodeErrorDetails) {
    return state.promo.promoCodeErrorDetails;
  }

  // If promo exists but doesn't contain an email discount,
  // it isn’t applicable for mail, return error object.

  if (
    state.promo.promoCodeDetails &&
    !state.promo.promoCodeDetails.config.discounts.site
  ) {
    const { id, displayCode } = state.promo.promoCodeDetails;
    return {
      errorCode: "PROMO_CODE_INVALID_FOR_MAIL",
      displayCode,
      id,
    } as TPromoCodeErrorDetails;
  }
};

export const selectSitePromoCodeDetailsForExitIntent = (state: RootState) => {
  const promoCodeDetails = selectSitePromoDetails(state);

  return (promoCodeDetails || SITE_PROMO_CODE_DETAILS) as TPromoCodeDetails;
};
