import { TIndustryIdentifier } from "../types/site-offering.type";
import { TIndustry } from "../types/industry.type";

/**
 * Resolves the industry identifier by checking if the given industry name exists in the provided list.
 * If found, returns its resource key; otherwise, treats it as a custom input and returns the name.
 */
export const resolveIndustryIdentifier = (
  industry: string,
  industries: TIndustry[]
): TIndustryIdentifier => {
  const normalizedInput = industry.toLowerCase();

  for (const industry of industries) {
    if (industry.name.toLowerCase() === normalizedInput) {
      return {
        industryKey: industry.resourceKey, // Always return the industry key
        ...(industry.value?.parentIndustryKey && {
          parentIndustryKey: industry.value.parentIndustryKey,
        }), // Conditionally include parentIndustryKey
      };
    }
  }

  return { industryName: industry }; // If not found, treat as a custom industry
};
