import {
  LAMBDA_FUNCTIONS,
  LAMBDA_PATH,
  SERVICE_TYPES,
} from "../../helpers/constants";
import { getConfig } from "../../helpers/utils";
import { TGenerateCelloTokenPayload } from "../../types/referral.type";
import apiManager from "../axiosConfig";
import { LAMBDA_ENTITY } from "../constant";

const { env } = getConfig() as { env: keyof typeof SERVICE_TYPES.BLL };

export const generateCelloToken = async (
  payload: TGenerateCelloTokenPayload,
  params = {}
) => {
  return apiManager.post(
    SERVICE_TYPES.LAMBDA[env as keyof typeof SERVICE_TYPES.LAMBDA],
    LAMBDA_PATH[env as keyof typeof LAMBDA_PATH],
    {
      payload: { ...payload },
      params: {
        entity: LAMBDA_ENTITY,
        env,
        functionName: LAMBDA_FUNCTIONS.GENERATE_CELLO_TOKEN,
        ...params,
      },
    }
  );
};

export const getCelloRewardDetails = async () => {
  return apiManager.get(
    SERVICE_TYPES.BLL[env],
    "external/cello/reward-details"
  );
};
