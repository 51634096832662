import { union } from "lodash";
import { getUtmParamsFromCookie } from "../helpers/utils";
import { store } from "../store/store";
import { getDomainDataFromStore } from "./domainAttributesMedusaHelper";
import { getMailboxesDataFromStore } from "./mailboxesAttributesMedusaHelper";
import { logMedusaGenericEvent } from "./medusaService";
import { getPersonaDataFromStore } from "./personaAttributesMedusaHelper";
import { getUserDataFromStore } from "./userAttributesMedusaHelper";
import { getVWOExperimentsDataFromStore } from "./vwoExperimentsAttributesMedusaHelper";
import { getPromoDataFromStore } from "./promoAttributesMedusaHelper";
import { getPlanDataFromStore } from "./planAttributesMedusa";

export const ANALYTICS_ATTRIBUTES = {
  CURRENCY: "currency",
  FREE_TRIAL_DAYS: "free_trial_days",

  // e5 events attribute
  ACCOUNT_ID: "account_id",
  BILLING_CYCLE: "billing_cycle",
  BUSINESS_INDUSTRY: "business_industry",
  CURRENT_EMAIL_APP: "current_email_app",
  CUSTOMER_EMAIL: "customer_email",
  CUSTOMER_ID: "customer_id",
  DOMAIN: "domain_name",
  DOMAIN_OWNERSHIP: "domain_ownership",
  EMAIL: "email",
  EMPLOYEE_COUNT: "employee_count",
  FIRST_NAME: "first_name",
  FIRST_ORDER: "first_order",
  GENERIC_MAILBOX_COUNT: "generic_mailbox_count",
  IMPORT_EMAILS_CONTACTS: "import_emails_contacts",
  LANDING_PAGE_NAME: "landing_page_name",
  LAST_NAME: "last_name",
  NAME_MAILBOX_COUNT: "name_mailbox_count",
  NEO_OFFERING: "neo_offering",
  MAIL_PLAN: "mail_plan",
  ORDER_ID: "order_id",
  PAYMENT_AMOUNT: "payment_amount",
  PROMO_DISCOUNT_AMOUNT: "promo_discount_amount",
  PAYMENT_FOR: "payment_for",
  MAIL_PRICE_MONTHLY: "mail_price_monthly",
  MAIL_FIRST_BILLING_DISCOUNT: "mail_first_billing_discount",
  ROLE_IN_BUSINESS: "role_in_business",
  SIGNUP_REASON: "signup_reason",
  SIGNUP_SOURCE: "signup_source",
  SOURCE_HOOK: "source_hook",
  SUGGESTED_MAILBOX_COUNT: "suggested_mailbox_count",
  TOTAL_MAILBOX_COUNT: "total_mailbox_count",
  USER_TYPE: "user_type",

  // charge for co.site new events
  DOMAIN_PRICE_MONTHLY: "domain_price_monthly",
  DOMAIN_PLAN: "domain_plan",
  DOMAIN_FIRST_BILLING_DISCOUNT: "domain_first_billing_discount",

  // vwo
  ACTIVE_EXPERIMENTS: "active_experiments",
  EXPERIMENTS: "experiments",

  // Promo
  PROMO_CODE: "promo_code",
  PROMO_CODE_ID: "promo_code_id",
  PROMO_DISCOUNT: "promo_discount",
  PROMO_VALIDITY_DAYS: "promo_validity_days",
  PROMO_CODE_ERROR: "promo_code_error",

  // site
  SITE_PRICE_FIRST_TWO_YEARS: "site_price_first_two_years",
  SITE_PRICE_FIRST_FOUR_YEARS: "site_price_first_four_years",
  SITE_PRICE_FIRST_YEAR: "site_price_first_year",
  SITE_PRICE_FIRST_QUARTER: "site_price_first_quarter",
  SITE_PRICE_MONTHLY: "site_price_monthly",
  SITE_FIRST_BILLING_DISCOUNT: "site_first_billing_discount",
  SITE_PRICE_QUARTERLY: "site_price_quarterly",
  SITE_PRICE_YEARLY: "site_price_yearly",
  SITE_PRICE_TWO_YEARLY: "site_price_two_yearly",
  SITE_PRICE_FOUR_YEARLY: "site_price_four_yearly",
  SITE_PLAN: "site_plan",

  // payable amount
  TOTAL_MAIL_PRICE: "total_mail_price",
  TOTAL_SITE_PRICE: "total_site_price",
  TOTAL_NEO_DOMAIN_PRICE: "total_neo_domain_price",
  BILLING_CYCLE_UPDATED: "billing_cycle_updated",

  // Referral
  FROM_REFERRAL_CODE: "from_referral_code",
};

/**
 *
 @param eventName {String} : Name of the event

 @param keysArr {Array} : Any additional keys that will be added to the final Medusa payload.
  These will be picked up from the KEY_STORE_MAP object inside the function

 @param  params {Object} : Custom attributes that need to be added to the final Medusa payload
  These will be sent from the Component that is calling this fn

 @return {Promise}: Returns a promise

 **/

export const logMedusaEvent = async (eventName, keysArr, params = {}) => {
  const storeData = store.getState();

  const experimentsData = await getVWOExperimentsDataFromStore(storeData);
  const domainData = getDomainDataFromStore(storeData);
  const mailboxesData = getMailboxesDataFromStore(storeData);
  const personaData = getPersonaDataFromStore(storeData);
  const planData = getPlanDataFromStore(storeData);
  const userData = getUserDataFromStore(storeData);
  const promoData = getPromoDataFromStore(storeData);

  const KEY_STORE_MAP = {
    ...domainData,
    ...experimentsData,
    ...mailboxesData,
    ...personaData,
    ...planData,
    ...userData,
    ...promoData,
  };

  let payload = {};

  const commonKeys = getCommonAttributesToLog();

  const finalKeysArr = union(commonKeys, keysArr);

  finalKeysArr.forEach((key) => {
    payload = {
      ...payload,
      [key]: KEY_STORE_MAP[key],
    };
  });

  const extendedPayload = {
    ...payload,
    ...params,
    ...getUtmParamsFromCookie(),
  };

  return logMedusaGenericEvent(eventName, extendedPayload);
};

const getCommonAttributesToLog = () => {
  const {
    ACTIVE_EXPERIMENTS,
    CURRENCY,
    EXPERIMENTS,
    FIRST_NAME,
    LAST_NAME,
    CUSTOMER_ID,
    CUSTOMER_EMAIL,
    DOMAIN,
    NEO_OFFERING,
    USER_TYPE,
    FROM_REFERRAL_CODE,
  } = ANALYTICS_ATTRIBUTES;
  return [
    ACTIVE_EXPERIMENTS,
    CURRENCY,
    EXPERIMENTS,
    FIRST_NAME,
    LAST_NAME,
    CUSTOMER_ID,
    CUSTOMER_EMAIL,
    DOMAIN,
    NEO_OFFERING,
    USER_TYPE,
    FROM_REFERRAL_CODE,
  ];
};
