import { useCallback, useEffect, useState } from "react";
import {
  getLastExitIntentShownAtFromCookie,
  getVisitorEmailFromCookie,
  isExitIntentDisabled,
  setLastExitIntentShownAtFromCookie,
  setVisitorEmailInCookie,
} from "../helpers/exitIntent.helper";
import { EXIT_INTENT_TYPES } from "../constants/exitIntent.constants";
import { isMobile } from "../helpers/utils";
import { Values } from "../types/common";
import { COOKIE_KEYS } from "../helpers/constants";
import { useMouseExitIntent } from "./dom/exit-intent/useMouseExitIntent";
import { useBackButtonExitIntent } from "./dom/exit-intent/useBackButtonExitIntent";

interface UseExitIntentOptions {
  disabled?: boolean;
  key: "mail" | "site";
}

const COOKIE_KEYS_MAPPING = {
  mail: {
    visitorEmail: COOKIE_KEYS.MAIL_VISITOR_EMAIL,
    lastExitIntent: COOKIE_KEYS.MAIL_LAST_EXIT_INTENT_SHOWN_AT,
  },
  site: {
    visitorEmail: COOKIE_KEYS.SITE_VISITOR_EMAIL,
    lastExitIntent: COOKIE_KEYS.SITE_LAST_EXIT_INTENT_SHOWN_AT,
  },
};

export const useExitIntent = (options?: UseExitIntentOptions) => {
  const key = options?.key || "mail";

  const visitorEmailCookieKey = COOKIE_KEYS_MAPPING[key].visitorEmail;
  const lastExitTimeCookieKey = COOKIE_KEYS_MAPPING[key].lastExitIntent;

  // Get initial values from cookies
  const initialVisitorEmail = getVisitorEmailFromCookie(visitorEmailCookieKey);
  const initialLastExitTime = getLastExitIntentShownAtFromCookie(
    lastExitTimeCookieKey
  );

  // State for exit intent type, visitor email, and the last time shown
  const [exitIntent, setExitIntent] = useState<Values<
    typeof EXIT_INTENT_TYPES
  > | null>(null);
  const [visitorEmail, setVisitorEmail] = useState<string | null>(
    initialVisitorEmail
  );
  const [lastExitIntentTime, setLastExitIntentTime] =
    useState<number>(initialLastExitTime);

  // Determine if exit intent should be disabled (based on email & last shown time)
  const isDisabled =
    options?.disabled || isExitIntentDisabled(visitorEmail, lastExitIntentTime);

  // Use custom hooks for detecting mouse exit and back button exit events.
  // Note: On mobile devices we disable mouse exit behavior.
  const mouseExited = useMouseExitIntent({
    disabled: isDisabled || isMobile(),
  });
  const backButtonExit = useBackButtonExitIntent({
    disabled: isDisabled,
    id: key,
  });

  // When either exit intent event is triggered, update state and cookies.
  useEffect(() => {
    if (!(mouseExited || backButtonExit)) return;
    const currentTime = Date.now();
    const intentType = mouseExited
      ? EXIT_INTENT_TYPES.MOUSE_EXIT
      : EXIT_INTENT_TYPES.BACK_BUTTON_EXIT;
    setExitIntent(intentType);
    setLastExitIntentTime(currentTime);
    setLastExitIntentShownAtFromCookie(currentTime, lastExitTimeCookieKey);
  }, [mouseExited, backButtonExit]);

  // Function to reset (hide) the exit intent
  const hideExitIntent = useCallback(() => {
    setExitIntent(null);
  }, []);

  // Function to update the visitor's email both in state and in cookie.
  const updateVisitorEmail = useCallback((email: string) => {
    setVisitorEmail(email);
    setVisitorEmailInCookie(email, visitorEmailCookieKey);
  }, []);

  return {
    exitIntent,
    visitorEmail,
    lastExitIntentTime,
    hideExitIntent,
    updateVisitorEmail,
  };
};
