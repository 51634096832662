import React from "react";
import VWOIntegration from "../../3rdPartyIntegrations/VWOIntegration/VWOIntegration";
import SetCJCookie from "../../3rdPartyIntegrations/CJCookie/CJCookie";
import Cello from "../../3rdPartyIntegrations/Cello";

const ThirdPartySDKS: React.FC = () => {
  return (
    <>
      <VWOIntegration />
      <SetCJCookie />
      <Cello />
    </>
  );
};

export default ThirdPartySDKS;
