import { store } from "../store/store";
import { RootState } from "../types/store.type";
import { getVerboseDiscount } from "../helpers/discount.helper";
import { getDaysLeft, parseUTCDate } from "../utils/dateUtils";
import { getProductFromUrlPath } from "../helpers/products.helper";
import { PRODUCT_TYPES } from "../constants/products.constants";
import { selectMailPromoDetails } from "../store/slices/mail.slice";
import { selectSitePromoDetails } from "../store/slices/site.slice";

export const getPromoDataFromStore = (storeState: RootState) => {
  const storeData = storeState || store.getState();
  const productType = getProductFromUrlPath(window.location.pathname);

  const isMailProduct = productType === PRODUCT_TYPES.MAIL;

  const promoCodeDetails = isMailProduct
    ? selectMailPromoDetails(storeData)
    : selectSitePromoDetails(storeData);

  const discount = promoCodeDetails?.discount;

  if (!promoCodeDetails || !discount) {
    return {};
  }

  return {
    promo_code: promoCodeDetails?.displayCode,
    promo_code_id: promoCodeDetails?.id,
    promo_discount: getVerboseDiscount(discount),
    promo_validity_days: getDaysLeft(
      parseUTCDate(promoCodeDetails?.expiryDate)
    ),
  };
};
