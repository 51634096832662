import { PRODUCT_TYPES } from "../constants/products.constants";
import {
  DOMAIN_TYPE,
  NEO_OFFERING_EVENT_VALUE,
  NUMBER_OF_MONTHS,
  OFFERING,
} from "../helpers/constants";
import { calculateDomainCharges } from "../helpers/domainPricingHelper";
import { getProductFromUrlPath } from "../helpers/products.helper";
import { getRoundedDecimal } from "../helpers/utils";
import {
  selectMailDomain,
  selectMailDomainPricing,
  selectMailPlan,
  selectMailPurchaseDomain,
} from "../store/slices/mail.slice";
import { selectNeoDomainTrialPlan } from "../store/slices/neo-domain-plans";
import {
  selectSiteDomain,
  selectSiteDomainPricing,
  selectSitePlan,
  selectSitePurchaseDomain,
} from "../store/slices/site.slice";
import { store } from "../store/store";

const { USER_CUSTOM_DOMAIN, CO_DOT_SITE } = NEO_OFFERING_EVENT_VALUE;

export const getDomainDataFromStore = (storeState) => {
  const storeData = storeState || store.getState();
  const domainPlan = selectNeoDomainTrialPlan(storeData);

  // TODO: Refactor this logic to dynamically handle different product types and their associated domain data.
  // This temporary solution uses conditional branching based on `productType` to select relevant data for each product.
  const productType = getProductFromUrlPath(window.location.pathname);
  let domainPricing;
  // let isDomainChargeable;
  let offering;
  let domain;
  let duration;
  if (productType === PRODUCT_TYPES.MAIL) {
    domainPricing = selectMailDomainPricing(storeData);
    // isDomainChargeable = selectMailDomainAttrs(storeData).isDomainChargeable;
    offering = selectMailDomain(storeData).offering;
    domain = selectMailPurchaseDomain(storeData);
    duration = selectMailPlan(storeData).duration;
  } else {
    domainPricing = selectSiteDomainPricing(storeData);
    // isDomainChargeable = selectSiteDomainAttrs(storeData).isDomainChargeable;
    offering = selectSiteDomain(storeData).offering;
    domain = selectSitePurchaseDomain(storeData);
    duration = selectSitePlan(storeData).duration;
  }

  // Get the multiplier based on the duration (monthly multiplier)
  const durationMultiplier = NUMBER_OF_MONTHS[duration] || 1;

  // Calculate the base domain charge without any discount (before multiplier)
  const baseDomainCharge = getRoundedDecimal(
    domainPricing[duration]?.renewalCharge * durationMultiplier,
    2
  );

  // Calculate the domain charges after any discounts have been applied
  const domainChargesAfterDiscount = calculateDomainCharges(
    duration,
    domainPricing
  );

  // Calculate the first billing discount percentage (based on the difference between base charge and discounted charges)
  const domainFirstBillingDiscountPercentage = getRoundedDecimal(
    ((baseDomainCharge - domainChargesAfterDiscount) / baseDomainCharge) * 100,
    2
  );

  return {
    domain_name: domain,
    domain_price_monthly: +domainPricing?.[duration]?.renewalCharge,
    domain_plan: domainPlan?.displayName,
    domain_first_billing_discount: domainFirstBillingDiscountPercentage,
    domain_ownership:
      offering === OFFERING.CUSTOM_DOMAIN
        ? DOMAIN_TYPE.ALREADY_HAVE_DOMAIN
        : DOMAIN_TYPE.DONT_HAVE_DOMAIN,
    neo_offering: offering
      ? offering === OFFERING.COSITE
        ? CO_DOT_SITE
        : USER_CUSTOM_DOMAIN
      : "",
  };
};
