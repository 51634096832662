import { TPromoCodeDetails } from "../types/promo.type";

export const EXIT_POPUP_MAX_AGE = 8 * 60 * 60 * 1000;

export const PITCH_TYPES = {
  EMAIL: "email",
  DOMAIN: "domain",
  SITE: "site",
} as const;

export const EXIT_INTENT_TYPES = {
  MOUSE_EXIT: "Outside viewport",
  BACK_BUTTON_EXIT: "Browser back",
} as const;

export const SITE_PROMO_CODE_DETAILS: TPromoCodeDetails = {
  id: Number(process.env.REACT_APP_PROMO_CODE_ID_SITE),
  entityId: 71,
  entityType: "PARTNER",
  displayCode: "EXTRA10",
  startDate: "2020-09-09",
  expiryDate: "2060-12-31",
  discount: {
    type: "percentage",
    value: 10,
  },
};

export const MAIL_PROMO_CODE_DETAILS: TPromoCodeDetails = {
  id: Number(process.env.REACT_APP_PROMO_CODE_ID_MAIL),
  entityId: 71,
  entityType: "PARTNER",
  displayCode: "EXTRA10",
  startDate: "2020-09-09",
  expiryDate: "2060-12-31",
  discount: {
    type: "percentage",
    value: 10,
  },
};
