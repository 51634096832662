import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import LOGOUT_ICON from "../../assets/logoutLogo.svg";
import SETTING_ICON from "../../assets/settingLogo.svg";
import SIGN_IN_CUSTOMER_ICON from "../../assets/signInCustomer.svg";
import SIGN_IN_WEBMAIL_ICON from "../../assets/signInWebmail.svg";
import SiteIcon from "../../assets/siteLogo.svg";
import {
  ANALYTICS_EVENTS,
  PAGE_PATHS,
  sourceHookMapping,
  WINDOW_NAME,
} from "../../helpers/constants";
import {
  getAdminPanelUrl,
  getWebmailUrl,
  redirectToNeoAdminPanel,
} from "../../helpers/utils";
import { logoutUser } from "../../helpers/user";
import { generateCpAutoLoginToken } from "../../services/apiFunctions";
import { userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import {
  logCustomerEvent,
  logSignInButtonClickEvent,
} from "../../telemetry/medusaEventsFunctions";
import { Values } from "../../types/common";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import { openSiteAppInNewTab } from "../../helpers/site";
import { getCommonSiteProductData } from "../../telemetry/site/siteMedusaData";
import { isOFACBlacklistedOrRestrictedRegionSelector } from "../../store/slices/ipDetails.slice";
import styles from "./UserProfile.module.scss";

const DROPDOWN_KEYS = {
  WEBMAIL: "webmail",
  ADMIN_PANEL: "admin_panel",
  LOGOUT: "logout",
  SIGN_IN: "sign_In",
  SITE: "site",
} as const;
type DropdownKeys = Values<typeof DROPDOWN_KEYS>;
const { WEBMAIL, ADMIN_PANEL, LOGOUT, SIGN_IN, SITE } = DROPDOWN_KEYS;
const WEBMAIL_URL = getWebmailUrl();
const { FLOCK_ADMIN, FLOCK_MAIL } = WINDOW_NAME;

const { CUSTOMER_ACCOUNT_SIGNED_OUT } = ANALYTICS_EVENTS;

export const logoutUserAndReportToMedusa = async (): Promise<void> => {
  logCustomerEvent(
    CUSTOMER_ACCOUNT_SIGNED_OUT,
    { ...getCommonSiteProductData() },
    true
  );
  logoutUser();
};

function UserProfile() {
  const {
    name,
    token,
    controlPanelAutoLoginToken,
    hasActiveOrders,
    alternateEmail,
  } = useSelector(userSelector);
  const { search_params } = useSelector(appSelector);
  const isRestrictedRegion = useSelector(
    isOFACBlacklistedOrRestrictedRegionSelector
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const dispatch = useDispatch();
  const { updateUser } = bindActionCreators(userActions, dispatch);

  useEffect(() => {
    // if the user is coming directly from the Neo Admin Panel, we will not have the NAP token,
    // in that case fetch the NAP token
    if (hasActiveOrders && !controlPanelAutoLoginToken) {
      generateCpAutoLoginToken({ token }).then((response) => {
        const res = response.data;

        if (response.status !== 200) {
          console.error("Error fetching control panel auth token", response);
          return;
        }
        updateUser({
          controlPanelAutoLoginToken: res.controlPanelAutoLoginToken,
        });
      });
    }
  }, [token, controlPanelAutoLoginToken, hasActiveOrders, updateUser]);

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const navigateTo = useNavigate();

  const handleDropdownClick = (key: DropdownKeys) => {
    setShowDropdown(false);
    switch (key) {
      case WEBMAIL:
        logSignInButtonClickEvent("header_sign_in_clicked", {
          source_hook: "Sign in to Neo Mail",
        });
        window.open(WEBMAIL_URL, FLOCK_MAIL);
        break;

      case ADMIN_PANEL:
        window.open(
          redirectToNeoAdminPanel(controlPanelAutoLoginToken, alternateEmail),
          FLOCK_ADMIN
        );
        break;

      case SITE:
        logSignInButtonClickEvent("header_sign_in_clicked", {
          source_hook: "Sign in to Neo Site",
        });
        openSiteAppInNewTab();
        break;

      case LOGOUT:
        logoutUserAndReportToMedusa();
        break;

      case SIGN_IN:
        logSignInButtonClickEvent("header_sign_in_clicked", {
          source_hook: "Sign in to customer account",
        });
        updateUser({ source_hook: sourceHookMapping["neoAvatarSignIn"] });
        if (isRestrictedRegion) {
          // If the user is in a restricted region, redirect him to login on the admin panel
          window.open(getAdminPanelUrl(), FLOCK_ADMIN);
        } else {
          navigateTo({
            pathname: PAGE_PATHS.SIGN_IN,
            search: search_params,
          });
        }
        break;

      default:
        break;
    }
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <>
      {token ? (
        <div ref={ref} className={styles.profileWrapper}>
          <div
            className={styles.userProfile}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className={styles.userIcon}>{name && name[0]}</div>
            <div className={styles.usernameWrapper}>
              <span className={styles.username}>{name}</span>
              <div
                className={classNames(styles.dropdownIcon, {
                  [styles.open]: showDropdown,
                })}
              />
            </div>
          </div>
          {showDropdown && (
            <div className={styles.dropdownList}>
              {hasActiveOrders && (
                <>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => handleDropdownClick(ADMIN_PANEL)}
                  >
                    <img alt="Settings" src={SETTING_ICON} />
                    Access Neo Admin Panel
                  </div>
                  {/* <hr className={styles.borderLine} /> */}
                </>
              )}
              <div
                className={styles.dropdownItem}
                onClick={() => handleDropdownClick(LOGOUT)}
              >
                <img alt="Logout" src={LOGOUT_ICON} />
                Logout
              </div>
            </div>
          )}
        </div>
      ) : (
        <div ref={ref} className={styles.profileWrapper}>
          <div
            className={classNames(styles.userProfile, styles.signInButton)}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            Sign in
            <div
              className={classNames(styles.dropdownIcon, {
                [styles.open]: showDropdown,
              })}
            />
          </div>
          {showDropdown && (
            <div className={styles.dropdownList}>
              {window.location.pathname !== PAGE_PATHS.SIGN_IN && (
                <div
                  className={styles.signedOutDropdownItem}
                  onClick={() => handleDropdownClick(SIGN_IN)}
                >
                  <img
                    alt="Sign in to customer account"
                    src={SIGN_IN_CUSTOMER_ICON}
                  />
                  <div>
                    <strong>Sign in to Customer account</strong>
                    <span>Manage your Neo subscription</span>
                  </div>
                </div>
              )}
              <div
                className={styles.signedOutDropdownItem}
                onClick={() => handleDropdownClick(WEBMAIL)}
              >
                <img alt="Sign in to Neo Mail" src={SIGN_IN_WEBMAIL_ICON} />
                <div>
                  <strong>Sign in to Neo Mail</strong>
                  <span>Access emails in your Inbox</span>
                </div>
              </div>
              <div
                className={styles.signedOutDropdownItem}
                onClick={() => handleDropdownClick(SITE)}
              >
                <img alt="Sign in to Neo Site" src={SiteIcon} />
                <div>
                  <strong>Sign in to Neo Site</strong>
                  <span>Access your AI site builder</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserProfile;
