import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUrlParams } from "../../helpers/utils";
import { PAGE_PATHS } from "../../helpers/constants";
import AppLoaderIcon from "../../assets/loader-blue.svg";
import { fetchWebflowUser } from "../../store/slices/webflow.slice";
import { AppDispatch } from "../../store/store";
import styles from "./WebflowIntegration.module.scss";

const WEBFLOW_PROMO_DETAILS = {
  discount_percent: "20",
  promo_code: "WEBFLOW20",
  promo_code_id: "2341492476986368",
};

const WEBFLOW_UTM_PARAMS = {
  utm_source: "webflow",
  utm_campaign: "marketplace",
};

const WebflowIntegration = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [showManualRedirection, setShowManualRedirection] = useState(false);

  const getRedirectUrlWithPromoDetailsAndUTMParams = () => {
    const baseUrl = `${window.location.origin}${PAGE_PATHS.GET_STARTED}`;
    const queryParams = new URLSearchParams({
      ...WEBFLOW_PROMO_DETAILS,
      ...WEBFLOW_UTM_PARAMS,
    }).toString();
    return `${baseUrl}?${queryParams}`;
  };

  const hardRedirectToGetStarted = () => {
    window.location.href = getRedirectUrlWithPromoDetailsAndUTMParams();
  };

  useEffect(() => {
    // Grab any query params
    const { code } = getUrlParams();

    // Show manual redirection link after 5s
    const timerId = setTimeout(() => {
      setShowManualRedirection(true);
    }, 5000);

    if (code) {
      (async () => {
        try {
          await dispatch(fetchWebflowUser(code)); // Fetch Webflow user details
        } catch (error: any) {
          console.error("Error fetching Webflow user details:", error);
        } finally {
          // Always redirect once API call finishes (success or error)
          hardRedirectToGetStarted();
        }
      })();
    } else {
      // No code param, just redirect
      hardRedirectToGetStarted();
    }

    // Cleanup on unmount
    return () => clearTimeout(timerId);
  }, [dispatch]);

  return (
    <div className={styles.contentWrapper}>
      <img className="loader" src={AppLoaderIcon} alt="app-loader" />
      <div className={styles.heading}>You will be redirected shortly...</div>
      {showManualRedirection && (
        <div className={styles.subheading}>
          Not redirected?{" "}
          <span onClick={hardRedirectToGetStarted}>Click here</span>
        </div>
      )}
    </div>
  );
};

export default WebflowIntegration;
