import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { cleanUrl, getUrlParams } from "../../helpers/utils";
import { isCoSiteDomain } from "../../helpers/domainHelpers";
import { PAGE_PATHS } from "../../helpers/constants";
import { useProduct } from "../../hooks/useProduct";
import { removeTrailingSlashes } from "../../utils/urlUtils";
import { appActions } from "../../store/actions";
import { isOFACBlacklistedOrRestrictedRegionSelector } from "../../store/slices/ipDetails.slice";

const VALID_PATHS = Object.values(PAGE_PATHS);

const useLayoutNavigation = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const productHandler = useProduct();
  const dispatch = useDispatch();
  const { updateAppParams } = bindActionCreators(appActions, dispatch);

  const isRestrictedRegion = useSelector(
    isOFACBlacklistedOrRestrictedRegionSelector
  );

  // TODO: window.location.search and location.search of useLocation are not synced properly so we need to use window.location.search. But this should be refactored.
  const navigateToPath = (path: string, options: NavigateOptions = {}) => {
    navigateTo(
      {
        pathname: path,
        search: window.location.search,
      },
      { replace: true, ...options }
    );
  };

  // Redirect to sign up page if the path is invalid
  useEffect(() => {
    if (!VALID_PATHS.includes(removeTrailingSlashes(location.pathname))) {
      navigateToPath(productHandler.signupPath);
    }
  }, [location.pathname]);

  const handleDomainNavigation = (domain: string, isCoSite: boolean) => {
    productHandler.setDomain(domain, isCoSite);
    navigateToPath(
      isCoSite ? productHandler.getDomainPath : productHandler.haveDomainPath,
      { state: { continueToNext: true } }
    );
  };

  // Redirect to the appropriate page based on the domain in the URL
  useEffect(() => {
    const { domain } = getUrlParams();
    if (domain) {
      handleDomainNavigation(domain, isCoSiteDomain(domain));
      const updatedSearchParams = cleanUrl(["domain"]);
      updateAppParams({ search_params: updatedSearchParams });
    }
  }, []);

  useEffect(() => {
    if (isRestrictedRegion) {
      navigateToPath(PAGE_PATHS.RESTRICTED_REGION);
    }
  }, [isRestrictedRegion]);
};

export default useLayoutNavigation;
