import {
  EXIT_INTENT_TYPES,
  EXIT_POPUP_MAX_AGE,
  PITCH_TYPES,
} from "../constants/exitIntent.constants";
import { TObject, Values } from "../types/common";
import { TUTMParams } from "../types/utm.type";
import { TMedusaPayload } from "../types/medusa";
import {
  logDomainEvent,
  logSiteExitIntentEvent,
} from "../telemetry/medusaEventsFunctions";
import { TPromoCodeDetails } from "../types/promo.type";
import { eraseCookie, getCookie, setCookie } from "./cookie.helper";
import { updateUTMParamsInCookie } from "./utm.helper";
import { triggerEmailTo as triggerEmailViaEnyo } from "./enyo.helper";
import { addQueryParamsInCurrentUrlAndStore } from "./urlHelpers";

export const getVisitorEmailFromCookie = (cookieKey: string): string | null => {
  return getCookie(cookieKey) || null;
};

export const setVisitorEmailInCookie = (email: string, cookieKey: string) => {
  setCookie(cookieKey, email, 7);
};

export const clearVisitorEmailInCookie = (cookieKey: string) => {
  eraseCookie(cookieKey);
};

export const clearVisitorEmailFromCookieIfSameAs = (
  email: string,
  cookieKeys: Array<string>
) => {
  cookieKeys.forEach((cookieKey) => {
    if (getVisitorEmailFromCookie(cookieKey) === email) {
      clearVisitorEmailInCookie(cookieKey);
    }
  });
};

export const getLastExitIntentShownAtFromCookie = (
  cookieKey: string
): number => {
  return parseInt(getCookie(cookieKey) || "0");
};

export const setLastExitIntentShownAtFromCookie = (
  time: number,
  cookieKey: string
) => {
  setCookie(cookieKey, time.toString());
};

export const isExitIntentDisabled = (
  visitorEmail: string | null,
  lastExitIntentTime: EpochTimeStamp
) => {
  return !!(
    visitorEmail ||
    (lastExitIntentTime && Date.now() - lastExitIntentTime < EXIT_POPUP_MAX_AGE)
  );
};

export const createMedusaPayload = ({
  exitIntent,
  pitch,
  pageName,
  discountPercentage,
}: {
  exitIntent: Values<typeof EXIT_INTENT_TYPES>;
  pitch: Values<typeof PITCH_TYPES>;
  pageName: string;
  discountPercentage: number;
}) => {
  return {
    exit_type: exitIntent,
    pitch: pitch === PITCH_TYPES.DOMAIN ? "Free domain" : "10% discount",
    page_name: pageName,
    discount_percent: pitch === PITCH_TYPES.DOMAIN ? 0 : discountPercentage,
    discount: pitch === PITCH_TYPES.DOMAIN ? "no" : "yes",
  };
};

export const updateSearchParamsAndUtmParams = (
  search_params: string,
  searchParamsObj: Record<string, any>,
  utmParams: TUTMParams
) => {
  const combinedParams = {
    ...searchParamsObj,
    ...utmParams,
  };

  const searchParamsObject = Object.fromEntries(
    new URLSearchParams(search_params)
  );

  addQueryParamsInCurrentUrlAndStore({
    ...{
      ...searchParamsObject,
      ...combinedParams,
    },
  });

  updateUTMParamsInCookie(utmParams);
};

// Logs a domain event using the given exit intent, pitch, location and discount.
export const onEvent = (
  eventName: string,
  {
    exitIntent,
    pitch,
    location,
    discountPercentage,
  }: {
    exitIntent: Values<typeof EXIT_INTENT_TYPES> | null;
    pitch: Values<typeof PITCH_TYPES>;
    location: { pathname: string };
    discountPercentage: number;
  },
  extraPayload: TMedusaPayload = {}
) => {
  if (!pitch || !exitIntent) return;
  const payload = {
    ...createMedusaPayload({
      exitIntent,
      pitch,
      pageName: location.pathname,
      discountPercentage,
    }),
    ...extraPayload,
  };
  pitch === PITCH_TYPES.SITE
    ? logSiteExitIntentEvent(eventName, payload)
    : logDomainEvent(eventName, payload);
};

// Adds UTM and promo code to the URL
export const addUTMAndPromoCodeToUrl = (
  search_params: any,
  utmData: TUTMParams,
  promoCodeId: number | undefined
) => {
  updateSearchParamsAndUtmParams(
    search_params,
    { promo_code_id: promoCodeId },
    utmData
  );
};

// Triggers an email, including influencer details if applicable.
export const triggerEmailTo = (
  email: string,
  templateName: string,
  options: {
    shouldUseInfluencerCode?: boolean;
    promoCodeDetails?: TPromoCodeDetails;
    discountPercentage?: number;
    additionalPayload?: TObject;
  }
) => {
  const {
    shouldUseInfluencerCode = false,
    promoCodeDetails,
    discountPercentage,
    additionalPayload = {},
  } = options;

  triggerEmailViaEnyo(
    email,
    templateName,
    {
      email,
      promo_code_id: `${promoCodeDetails?.id}`,
      discount_percentage: `${discountPercentage}`,
      display_code: promoCodeDetails?.displayCode,
      influencer: shouldUseInfluencerCode,
    },
    additionalPayload
  );
};
