import { getCoSiteTld } from "./utils";

const NEO_BUSINESS_DOMAINS = [
  "co.site",
  "copreprod.site",
  "costaging.site",
];

const cleanDomain = (domain = "") => {
  return domain?.toLowerCase()?.trim();
};

export const getDomainFromEmail = (email = "") => {
  return email.split("@")?.[1];
};

export const isCoSiteDomain = (domain = "") => {
  domain = domain?.toLowerCase()?.trim();
  const coSiteTld = getCoSiteTld();
  return domain?.endsWith(coSiteTld);
};

export const isCustomDomain = (domain = "") => {
  return !isCoSiteDomain(domain);
};

export const getDomainWithoutTld = (domain = "") => {
  domain = cleanDomain(domain);
  return domain?.split(".")[0];
};

// Helper function to extract the root domain (removes subdomains)
export const getRootDomain = (hostname: string) => {
  const parts = hostname.split(".");
  return parts.length > 2 ? parts.slice(parts.length - 2).join(".") : hostname;
};

export const isNeoBusinessDomain = (domain: string) => {
  const isNeoDomain = NEO_BUSINESS_DOMAINS.some((neoDomain) => {
    const regex = new RegExp(`${neoDomain}`, "gi");
    return regex.test(domain);
  });
  return isNeoDomain;
};

const ERROR_MESSAGES: Record<string, (domain: string) => string> = {
  DOMAIN_ALREADY_EXISTS: (domain) =>
    `If you own ${domain} and want to add another mailbox, go to `,
  DOMAIN_BELONGS_TO_CUSTOMER: (domain) =>
    `If you already own ${domain} and want to add another mailbox, go to `,
};

export const getCustomDomainErrorMsg = (reason: string, domain: string): string => {
  return ERROR_MESSAGES[reason]?.(domain) || `Error: Invalid reason "${reason}" provided.`;
};


export const formatNameServers = (nameServers?: string[]): string => {
  return nameServers ? nameServers.join(", ") : "";
};

export const getSubdomain = (domain: string): string => {
  if (!domain.includes(".")) return domain;
  return domain.split(".").slice(1).join(".");
};