import {
  COOKIE_KEYS,
  ENVIRONMENTS,
  NEO_PARTNER_ID_NUMBER,
} from "../helpers/constants";
import {
  getBrowser,
  getConfig,
  getDeviceIdFromCookie,
  getHotjarIdFromCookie,
  getOS,
  getUrlParams,
  isMobile,
} from "../helpers/utils";
import { setCookie } from "../helpers/cookie.helper";
import {
  insertIntoVisitorTable,
  logMedusaAnalytics,
} from "../services/apiFunctions";
import { store } from "../store/store";
import { getProductFromUrlPath } from "../helpers/products.helper";

const NEO_BRAND = "Neo";

const { env } = getConfig();
const environment = ENVIRONMENTS[env] || "unknown";

let refDomain = "";

const referrer = document.referrer;
if (referrer !== "") {
  const referrerUrl = new URL(referrer) || "";
  refDomain = referrerUrl.hostname;
}

export const getDeviceInfo = () => {
  return {
    browser: getBrowser(),
    device: isMobile() ? "mobile" : "desktop",
    device_id: getDeviceIdFromCookie(),
    os: getOS(),
    timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || "Unknown",
    referrer: document.referrer,
    referring_domain: refDomain,
    screen_height_pixel: window.innerHeight,
    screen_width_pixel: window.innerWidth,
    url: window.location.href,
  };
};

export const logMedusaGenericEvent = (eventName, payload = {}) => {
  const storeState = store.getState();

  const {
    app: { page_variant },
    user: { customer_id },
  } = storeState;

  /**
   * Generate hotjar id on signup app if received unknown in search params from landing page
   * OR directly went to signup app
   */
  const { HOTJAR_ID } = COOKIE_KEYS;
  if (!getHotjarIdFromCookie() && window?.hj?.globals?.get("userId")) {
    setCookie(HOTJAR_ID, window?.hj?.globals?.get("userId"));
  }

  // Destructure and handle payload keys
  const {
    entityId: payloadEntityId,
    product = "website",
    dummy_entity_Id,
    entityType = "titan_customer",
    hattrs,
    ...remainingPayload
  } = payload;

  // Resolve entityId with correct priority: payload > dummy_entity_Id > customer_id
  const resolvedEntityId =
    payloadEntityId ||
    (dummy_entity_Id
      ? "dummy_entity_id"
      : customer_id?.toString() || "dummy_entity_id");

  if (!getDeviceIdFromCookie()) {
    console.error(
      `Device ID missing. Event Name: ${eventName}. HotJar ID: ${getHotjarIdFromCookie()}. Device: ${getBrowser()} on ${getOS()} ${
        isMobile() ? "mobile" : "desktop"
      }`
    );
  }

  const { gclid, source } = getUrlParams();

  // Construct the extended payload
  const extendedPayload = {
    eventTs: Date.now(),
    product,
    entityId: `${resolvedEntityId}`,
    eventName,
    type: entityType,
    attrs: {
      brand: NEO_BRAND,
      environment,
      hotjar_id: getHotjarIdFromCookie() || "unknown",
      page_variant,
      page_name: remainingPayload.page_name || page_variant,
      partner_id: NEO_PARTNER_ID_NUMBER[env],
      neo_product: "Business",
      product_source: getProductFromUrlPath(window.location.pathname),
      gclid,
      source,
      ...getDeviceInfo(),
      ...remainingPayload,
    },
    ...(hattrs && { hattrs }),
  };

  return logMedusaAnalytics(extendedPayload).then((res) => {
    if (res?.status === 200 && hattrs) {
      // Trigger parallel calls for hattrs identifiers
      Object.keys(hattrs).forEach((key) => addIdentifierForHattrs(key));
    } else if (res?.status !== 200) {
      console.error(
        `There was an error sending the event. Error Code: ${
          res?.status || "unknown"
        }. Payload: ${JSON.stringify(extendedPayload)}`
      );
    }

    return res;
  });
};

export const addIdentifierForHattrs = (identifier) => {
  return insertIntoVisitorTable({ identifier, tenant: "neo" });
};
