import { getConfig } from "./utils";

const { env } = getConfig();

export const isProduction = (
  () => () =>
    env === "PROD"
)();

export const isPreprod = (
  () => () =>
    env === "PREPROD"
)();

export const isStaging = (
  () => () =>
    env === "STAGING"
)();
