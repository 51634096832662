import { DEFAULT_CURRENCY } from "../constants/currency";
import { getInviterCelloUCC } from "../helpers/referral.helper";
import { getSpiltFirstAndLastName } from "../helpers/utils";
import { appSelector, userSelector } from "../store/selectors";
import { store } from "../store/store";

export const getUserDataFromStore = (storeState) => {
  const storeData = storeState || store.getState();

  const user = userSelector(storeData);
  const search_params = appSelector(storeData);
  const {
    account_id,
    alternateEmail,
    customer_id,
    firstOrder,
    name,
    order_id,
    source_hook,
    token,
    currency,
    // TODO:  fix this
    payment_amount,
    payment_for,
    total_site_price,
    total_mail_price,
    total_neo_domain_price,
    billing_cycle_updated,
    referralDetails,
  } = user;

  const { firstName, lastName } = getSpiltFirstAndLastName(name) || {};

  return {
    account_id,
    customer_email: alternateEmail,
    customer_id,
    first_name: firstName,
    first_order: firstOrder,
    last_name: lastName,
    order_id,
    source_hook,
    user_type: token ? "Customer" : "Visitor",
    currency: currency || DEFAULT_CURRENCY,
    payment_amount,
    payment_for,
    total_site_price,
    total_mail_price,
    total_neo_domain_price,
    billing_cycle_updated,
    from_referral_code:
      getInviterCelloUCC(search_params) || referralDetails?.inviterCelloUCC,
  };
};
