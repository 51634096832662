import { openDB, IDBPDatabase } from 'idb';
import { DB_NAME, DB_VERSION, STORE_NAMES } from '../constants/idb.constant';


const { VISITOR } = STORE_NAMES;
const PRODUCT = 'neo_signup';

interface NeoSignupEntry<T> {
  product: string;
  key: string;
  value: T;
  updatedAt: number;
}

let dbPromise: Promise<IDBPDatabase> | null = null;

async function initDB(): Promise<IDBPDatabase> {
  if (!dbPromise) {
    dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        const store = db.createObjectStore(VISITOR, {
          keyPath: ['product', 'key'],
        });

        store.createIndex('product', 'product');
        store.createIndex('key', 'key');
      },
      blocked() {
        console.error('IDB is blocked');
      }
    });
  }

  return dbPromise;
}

export async function getIdb<T>(key: string): Promise<NeoSignupEntry<T> | undefined> {
  const db = await initDB();
  const tx = db.transaction(VISITOR, 'readonly');
  const store = tx.objectStore(VISITOR);
  const record = await store.get([PRODUCT, key]);
  await tx.done;
  return record;
}

export async function setIdb<T>(key: string, value: T): Promise<void> {
  const db = await initDB();
  const tx = db.transaction(VISITOR, 'readwrite');
  const store = tx.objectStore(VISITOR);
  const entry: NeoSignupEntry<T> = {
    product: PRODUCT,
    key,
    value,
    updatedAt: Date.now(),
  };
  await store.put(entry); 
  await tx.done;
}

export async function removeIdb(key: string): Promise<void> {
  const db = await initDB();
  const tx = db.transaction(VISITOR, 'readwrite');
  const store = tx.objectStore(VISITOR);
  await store.delete([PRODUCT, key]); 
  await tx.done;
}
