import { getUserNameFromEmail } from "../../../helpers/emailHelpers";
import { TReferralDetails } from "../../../types/referral.type";

type LoginResponse = {
  actor?: {
    attrs?: {
      userName?: string;
    };
    email?: string;
  };
  userEmail?: string | null;
  bllCustomerId: string;
  token: string;
  expiryEpochTimestamp: number;
  primaryDomain?: string | null;
  bllUserId: number;
  referralDetails?: TReferralDetails;
  userState: number;
  [key: string]: any;
};

type AutoLoginResponse = {
  name: string;
  email: string;
  accountId: number;
  domainId: number;
  domainName: string;
  userId: number;
  userEmail: string;
  partnerId: string;
  createdAt: string;
  expiryEpochTimestamp: number;
  token: string;
  referralDetails?: TReferralDetails;
  userState: number;
};

type UserStore = {
  name: string;
  token: string;
  tokenExpiry: number;
  alternateEmail: string;
  hasActiveOrders: boolean;
  firstOrder: boolean;
  billingInformation: {
    yourName: string;
    [key: string]: any;
  };
  customer_id: number;
  referralDetails?: TReferralDetails;
  userState: number;
};

/**
 * @desc Maps the login response data to the user store object format.
 * @param loginRes - The login response object containing user data.
 * @param user - The current user data.
 * @returns {UserStore} - The user store object to be used in the state update.
 */
export const mapLoginResponseToUserStore = (
  loginRes: LoginResponse,
  user: any
): UserStore => {
  const {
    actor,
    userEmail,
    token: authToken,
    expiryEpochTimestamp,
    primaryDomain,
    bllUserId,
    referralDetails,
    userState,
  } = loginRes;

  const email = userEmail || actor?.email || "";
  const name = actor?.attrs?.userName || getUserNameFromEmail(email);
  const hasActiveOrders = !!primaryDomain;

  return {
    name,
    token: authToken,
    tokenExpiry: expiryEpochTimestamp,
    alternateEmail: email,
    hasActiveOrders,
    firstOrder: !hasActiveOrders,
    billingInformation: {
      ...user.billingInformation,
      yourName: name,
    },
    customer_id: bllUserId,
    referralDetails,
    userState,
  };
};

export const mapAutoLoginResponseToUserStore = (
  loginRes: AutoLoginResponse,
  user: any
): UserStore => {
  const {
    name,
    expiryEpochTimestamp,
    domainName,
    userEmail,
    userId,
    token,
    referralDetails,
    userState,
  } = loginRes as AutoLoginResponse;
  const hasActiveOrders = !!domainName;

  return {
    name: name || getUserNameFromEmail(userEmail) || "",
    token,
    tokenExpiry: expiryEpochTimestamp,
    alternateEmail: userEmail,
    hasActiveOrders,
    firstOrder: !hasActiveOrders,
    billingInformation: {
      ...user.billingInformation,
      yourName: name,
    },
    customer_id: userId,
    referralDetails,
    userState,
  };
};
