import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { userSelector } from "../selectors";
import { getPromoDetails } from "../../services/apiFunctions";
import { STATUS_CODES } from "../../constants/api.constants";
import { parsePromoErrorResponse } from "../../helpers/promo.helper";
import {
  TFetchPromoArg,
  TPromoCodeDiscounts,
  TPromoCodeErrorDetails,
  TPromoCodeResponseDetails,
  TPromoState,
} from "../../types/promo.type";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../types/store.type";

const initialState: TPromoState = {};

export const promoSlice = createSlice({
  name: "promo",
  initialState,
  reducers: {
    updatePromo: (state, action: PayloadAction<TPromoState>) => {
      return action.payload;
    },
    resetPromo: () => ({ ...initialState }),
  },
  extraReducers(builder: ActionReducerMapBuilder<TPromoState>) {
    builder.addCase(fetchPromoDetails.fulfilled, (state, action) => {
      state.promoCodeDetails = action.payload;
    });
    builder.addCase(fetchPromoDetails.rejected, (state, action) => {
      Object.assign(state, initialState);
      const payload = action.payload as TPromoCodeErrorDetails;
      if (action.payload) state.promoCodeErrorDetails = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { updatePromo, resetPromo } = promoSlice.actions;

export default promoSlice.reducer;

export const fetchPromoDetails = createAsyncThunk(
  "fetch/promo",
  async (arg: TFetchPromoArg, { getState, rejectWithValue }) => {
    const { payload, flags } = arg;
    const { token } = userSelector(getState() as RootState);
    const response = await getPromoDetails({
      ...(token ? { token } : {}),
      ...payload,
    });

    if (response?.status === STATUS_CODES.OK) {
      return {
        ...response.data.promoCode,
        influencerPromoCode: flags?.influencerPromoCode,
      } as TPromoCodeResponseDetails;
    }

    const promoCodeErrorDetails = parsePromoErrorResponse(
      response,
      "promoCodeId" in payload ? payload.promoCodeId : payload.promoCode
    );
    if (promoCodeErrorDetails) {
      return rejectWithValue({
        ...promoCodeErrorDetails,
        influencerPromoCode: flags?.influencerPromoCode,
      } as TPromoCodeErrorDetails);
    }
    throw new Error("Failed to fetch promo code.");
  }
);

export const selectPromoDiscounts = (
  state: RootState
): TPromoCodeDiscounts | null => {
  const promoCodeDetails = state.promo.promoCodeDetails;
  if (!promoCodeDetails) {
    return null;
  }

  const { config } = promoCodeDetails;

  // If the promo contains discounts, return the details.
  if (config.discounts) {
    return config.discounts as TPromoCodeDiscounts;
  }

  return null;
};
