import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import Zendesk from "react-zendesk";
import { bindActionCreators } from "redux";
import WebflowIntegration from "../../3rdPartyIntegrations/WebflowIntegration/WebflowIntegration";
import {
  COOKIE_KEYS,
  CURRENT_PAGE_VIEWED,
  PAGE_PATHS,
  ZENDESK_KEY,
} from "../../helpers/constants";
import { eraseCookie, getCookie, setCookie } from "../../helpers/cookie.helper";
import { onVWOLoaded } from "../../helpers/pageLoadedHelper";
import { showErrorToast } from "../../helpers/toastHelper";
import { generateNewDeviceId } from "../../helpers/utils";
import useResetMailDomain from "../../hooks/mail/useResetMailDomain";
import { useProduct } from "../../hooks/useProduct";
import { logoutActions, userActions } from "../../store/actions";
import { appSelector, userSelector } from "../../store/selectors";
import { IPDetailsSelector } from "../../store/slices/ipDetails.slice";
import { logPageViewedEvent } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_ATTRIBUTES } from "../../telemetry/medusaHelper";

import Logo from "../Logo";
import UserProfile from "../UserProfile/UserProfile";
import CountryNotSupported from "../CountryNotSupported";
import CustomDomainMobileSetup from "../CustomMobileSetup/CustomMobileSetup";
import useLayoutNavigation from "./useLayoutNavigation";
import RouteProtection from "../RouteProtection/RouteProtection";
import SiteRouteProtection from "../RouteProtection/Site/SiteRouteProtection";
import Loader from "../Common/Loader/Loader";
import styles from "./Layout.module.scss";

// Mail component
const MailSignIn = lazy(() => import("../SignIn/Mail"));
const MailSignUp = lazy(() => import("../SignUp/Mail"));
const MailGetDomain = lazy(() => import("../GetDomain/Mail"));
const MailGetStarted = lazy(() => import("../GetStarted/Mail"));
const MailHaveDomain = lazy(() => import("../HaveDomain/Mail"));
const MailAddMailbox = lazy(() => import("../AddMailbox/Mail"));
const AddGenericMailbox = lazy(() =>
  import("../AddGenericMailbox/AddGenericMailbox")
);
const AddTeamMailbox = lazy(() => import("../AddTeamMailbox/AddTeamMailbox"));
const PreviewSite = lazy(() => import("../PreviewSite/PreviewSite"));
const MailPlans = lazy(() => import("../Plans/mail"));
const MailBilling = lazy(() => import("../Billing/mail"));
const AllDone = lazy(() => import("../AllDone/AllDone"));
const Persona = lazy(() => import("../Persona/Persona"));
const Referral = lazy(() => import("../Referral"));

// Site component
const SiteSignIn = lazy(() => import("../SignIn/Site"));
const SiteSignUp = lazy(() => import("../SignUp/Site"));
const SiteGetDomain = lazy(() => import("../GetDomain/Site"));
const SiteGetStarted = lazy(() => import("../GetStarted/Site"));
const SiteHaveDomain = lazy(() => import("../HaveDomain/Site"));
const SiteAddMailbox = lazy(() => import("../AddMailbox/Site"));
const SitePlans = lazy(() => import("../Plans/site"));
const SiteBilling = lazy(() => import("../Billing/site"));
const SiteOffering = lazy(() => import("../Site/SiteOfferingOnboard"));
const SiteDomainSelection = lazy(() => import("../DomainSelection/Site"));

const { LANDING_PAGE_NAME } = ANALYTICS_ATTRIBUTES;

const {
  SIGN_IN,
  SIGN_UP,
  ADD_MAILBOX,
  ADD_TEAM_MAILBOX,
  ADD_GENERIC_MAILBOX,
  PLANS,
  BILLING,
  ALL_DONE,
  PERSONA,
  PREVIEW_SITE,
  GET_STARTED,
  GET_STARTED_V2,
  HAVE_DOMAIN,
  GET_DOMAIN,
  ADMIN_LOGIN_INFO,
  REFER,

  // misc
  RESTRICTED_REGION,

  //Site
  INDUSTRY,
  YOUR_IDEA,
  SITE_SIGN_IN,
  SITE_SIGN_UP,
  SITE_GET_STARTED,
  SITE_GET_STARTED_V2,
  SITE_HAVE_DOMAIN,
  SITE_GET_DOMAIN,
  SITE_ADD_MAILBOX,
  SITE_BILLING,
  SITE_PLANS,
  SITE_DOMAIN_SETUP,

  // integrations
  WEBFLOW,
} = PAGE_PATHS;

const { DEVICE_ID, HOTJAR_ID, CUSTOMER_ID_COOKIE } = COOKIE_KEYS;

const BUFFER_TIME = 900000; // 15 minutes in epoch millis

function Layout() {
  const containerRef = useRef(null);

  const { search_params } = useSelector(appSelector);
  const { token, tokenExpiry } = useSelector(userSelector);
  const { countryName } = useSelector(IPDetailsSelector);

  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [isFirstRender, setIsFirstRender] = useState(true);
  // This hook is called to reset the domain state if the purchased domain matches the custom or co-site domain.
  // It ensures that the mail domain data is cleared when transitioning to a new flow, allowing for a clean state.
  // Order of below hooks also matters. As first we want to clear things and then navigate to new page with setting data in store if required to set.
  useResetMailDomain(); // Resets domain state for new flow
  useLayoutNavigation();

  const location = useLocation();
  const productHandler = useProduct();

  const authenticatedPages = [
    ADD_MAILBOX,
    ADD_TEAM_MAILBOX,
    ADD_GENERIC_MAILBOX,
    PLANS,
    BILLING,
    ALL_DONE,
    PREVIEW_SITE,
    PERSONA,

    // site
    SITE_ADD_MAILBOX,
    SITE_BILLING,
    SITE_PLANS,
  ];

  const { updateLogout } = bindActionCreators(logoutActions, useDispatch());
  const { clearSession } = bindActionCreators(userActions, useDispatch());
  useEffect(() => {
    async function handleTokenExpiry() {
      if (isLoggedIn && tokenExpiry) {
        const currentDateTime = Date.now();

        if (currentDateTime >= tokenExpiry - BUFFER_TIME) {
          // added a 15 mins buffer time to prevent token from expiring while user is on the flow

          const isAuthenticatedPage = authenticatedPages.includes(
            window.location.pathname
          );
          showErrorToast(
            isAuthenticatedPage
              ? "Your session has expired. You will be redirected to the sign in page. Please login again."
              : "Your session has expired.",
            {
              autoClose: 3000,
              toastId: "token-expired",
            }
          );
          /**
           * To logout user for authenticated pages we are using clearStore and
           * For unauthenticated pages we are using updateLogout action
           * The reason behind not to use updateLogout action for authenticated pages
           is we are setting the store to initial state with domain as empty and
           Route protection component will redirect the user to get-started page
           if domain is empty for fraction of second before redirecting to signin page
           */
          eraseCookie(CUSTOMER_ID_COOKIE);
          // await clearStore();
          clearSession();
          if (isAuthenticatedPage) {
            setTimeout(() => {
              productHandler.navigateToSignIn(search_params);
            }, 3000);
          } else {
            updateLogout();
          }
        }
      }
    }

    handleTokenExpiry();
  }, [isLoggedIn, tokenExpiry, location]);

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  const logPageViewedMedusa = (path) => {
    logPageViewedEvent({
      [LANDING_PAGE_NAME]: CURRENT_PAGE_VIEWED[path],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // log website_page_viewed event
    onVWOLoaded().then(() => {
      if (isFirstRender) {
        setIsFirstRender(false);
        setTimeout(() => logPageViewedMedusa(location.pathname), 250);
      } else {
        logPageViewedMedusa(location.pathname);
      }
    });

    // On url change check for device id and hotjar id if not exist in cookie then update
    if (!getCookie(DEVICE_ID)) {
      setCookie(DEVICE_ID, generateNewDeviceId());
    }
    setTimeout(() => {
      if (!getCookie(HOTJAR_ID) && window?.hj?.globals?.get("userId")) {
        setCookie(HOTJAR_ID, window?.hj?.globals?.get("userId"));
      }
    }, 3000);
  }, [location.pathname]);

  const getProtectedRoute = (element) => {
    return <RouteProtection>{element}</RouteProtection>;
  };

  const getSiteRouteProtection = (element) => {
    return <SiteRouteProtection>{element}</SiteRouteProtection>;
  };

  /**
   * Load zendesk chat widget after containerRef div mounts
   * To pass the horizontal offset value to zendesk chat widget to get positioned correctly
   */
  const zendeskLoadPages = [ALL_DONE];
  const [loadZendesk, setLoadZendesk] = useState(false);
  const [zendeskSetting, setZendeskSetting] = useState(null);
  useEffect(() => {
    if (zendeskLoadPages.includes(location.pathname)) {
      setZendeskSetting({
        color: {
          theme: "#3872B2",
        },
        offset: {
          horizontal:
            window.innerWidth -
            containerRef?.current?.getBoundingClientRect().right +
            "px",
        },
        chat: {
          departments: {
            enabled: [],
          },
        },
      });
      setLoadZendesk(true);
    }
  }, [location]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.logo}>
        <Logo />
        <UserProfile />
      </div>
      <div className={styles.content}>
        <main>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* MAIL ROUTES */}
              <Route path={SIGN_UP} element={<MailSignUp />} />
              <Route path={SIGN_IN} element={<MailSignIn />} />
              <Route path={GET_STARTED} element={<MailGetStarted />} />
              <Route path={GET_STARTED_V2} element={<MailGetStarted />} />
              <Route path={HAVE_DOMAIN} element={<MailHaveDomain />} />
              <Route path={GET_DOMAIN} element={<MailGetDomain />} />
              <Route
                path={ADD_MAILBOX}
                element={getProtectedRoute(<MailAddMailbox />)}
              />
              <Route
                path={BILLING}
                element={getProtectedRoute(<MailBilling />)}
              />
              <Route path={PLANS} element={getProtectedRoute(<MailPlans />)} />
              <Route
                path={ADD_TEAM_MAILBOX}
                element={getProtectedRoute(<AddTeamMailbox />)}
              />
              <Route
                path={ADD_GENERIC_MAILBOX}
                element={getProtectedRoute(<AddGenericMailbox />)}
              />

              <Route path={ALL_DONE} element={getProtectedRoute(<AllDone />)} />
              <Route path={PERSONA} element={getProtectedRoute(<Persona />)} />
              <Route
                path={PREVIEW_SITE}
                element={getProtectedRoute(<PreviewSite />)}
              />
              <Route path={REFER} element={<Referral />} />

              {/* SITE ROUTES */}
              <Route path={INDUSTRY} element={<SiteOffering />} />
              <Route path={YOUR_IDEA} element={<SiteOffering />} />
              <Route path={SITE_SIGN_UP} element={<SiteSignUp />} />
              <Route path={SITE_SIGN_IN} element={<SiteSignIn />} />
              <Route
                path={SITE_GET_STARTED}
                element={getSiteRouteProtection(<SiteGetStarted />)}
              />
              <Route
                path={SITE_GET_STARTED_V2}
                element={getSiteRouteProtection(<SiteGetStarted />)}
              />
              <Route
                path={SITE_DOMAIN_SETUP}
                element={getSiteRouteProtection(<SiteDomainSelection />)}
              />
              <Route path={SITE_HAVE_DOMAIN} element={<SiteHaveDomain />} />
              <Route path={SITE_GET_DOMAIN} element={<SiteGetDomain />} />
              <Route
                path={SITE_PLANS}
                element={getProtectedRoute(<SitePlans />)}
              />
              <Route
                path={SITE_BILLING}
                element={getProtectedRoute(<SiteBilling />)}
              />
              <Route
                path={SITE_ADD_MAILBOX}
                element={getProtectedRoute(<SiteAddMailbox />)}
              />

              <Route
                path={ADMIN_LOGIN_INFO}
                element={getProtectedRoute(<CustomDomainMobileSetup />)}
              />
              <Route path={WEBFLOW} element={<WebflowIntegration />} />
              <Route
                path={RESTRICTED_REGION}
                element={
                  <CountryNotSupported
                    ipCountry={countryName}
                    subtext={"Neo is not currently available in your country."}
                  />
                }
              />
            </Routes>
          </Suspense>
        </main>
      </div>
      {loadZendesk && (
        <Zendesk
          defer
          zendeskKey={ZENDESK_KEY}
          onLoaded={() => {
            window.zE("messenger", "close");
            console.info("Zendesk loaded");
          }}
          {...zendeskSetting}
        />
      )}
    </div>
  );
}

export default Layout;
