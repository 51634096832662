import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import React, { useEffect, useMemo } from "react";
import { PITCH_TYPES } from "../../../constants/exitIntent.constants";
import { ANALYTICS_EVENTS, PAGE_PATHS } from "../../../helpers/constants";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import { appSelector } from "../../../store/selectors";
import { getUrlParams } from "../../../helpers/utils";
import { removeTrailingSlashes } from "../../../utils/urlUtils";
import { getDiscountPercentage } from "../../../helpers/discount.helper";
import {
  addUTMAndPromoCodeToUrl,
  onEvent,
  triggerEmailTo,
} from "../../../helpers/exitIntent.helper";
import { useExitIntent } from "../../../hooks/useExitIntent";
import { selectMailPromoCodeDetailsForExitIntent } from "../../../store/slices/mail.slice";
import DomainPitchModal from "./DomainPitchModal";
import EmailPitchModal from "./EmailPitchModal";

const config = {
  [PAGE_PATHS.GET_STARTED]: { pitch: PITCH_TYPES.EMAIL },
  [PAGE_PATHS.HAVE_DOMAIN]: { pitch: PITCH_TYPES.EMAIL },
  [PAGE_PATHS.GET_DOMAIN]: { pitch: PITCH_TYPES.DOMAIN },
};

const getUTMData = (discountPercentage: number, isEmailPitch: boolean) => ({
  utm_source: "neo-mail",
  utm_campaign: "visitor-exit",
  utm_content: isEmailPitch
    ? `discount-${discountPercentage}-percent`
    : "free-domain",
});

const RADIX_PROMO_UTM_SOURCE = "radix";

const ExitIntent = () => {
  const loggedIn = useIsLoggedIn();
  const location = useLocation();
  const { search_params } = useSelector(appSelector);
  const { pitch } = useMemo(
    () => config[removeTrailingSlashes(location.pathname)] || {},
    [location]
  );

  const isEmailPitch = pitch === PITCH_TYPES.EMAIL;
  const isDomainPitch = pitch === PITCH_TYPES.DOMAIN;

  const isRadixPromoFlow = useMemo(() => {
    const { utm_source } = getUrlParams();
    return utm_source === RADIX_PROMO_UTM_SOURCE;
  }, []);

  // External disable conditions: logged in, no pitch, or a Radix promo flow.
  const externalDisabled = loggedIn || !pitch || isRadixPromoFlow;

  // Use the custom hook to manage exit intent state and helpers.
  const { exitIntent, hideExitIntent, updateVisitorEmail } = useExitIntent({
    disabled: externalDisabled,
    key: "mail",
  });

  const promoCodeDetails = useSelector(selectMailPromoCodeDetailsForExitIntent);
  const influencerPromoCode = promoCodeDetails.influencerPromoCode;
  const emailAccountDiscount = promoCodeDetails.discount;
  const discountPercentage = emailAccountDiscount
    ? getDiscountPercentage(emailAccountDiscount)
    : 10;

  useEffect(() => {
    if (!exitIntent) return;
    onEvent(ANALYTICS_EVENTS.EXIT_POPUP_VIEWED, {
      exitIntent,
      pitch,
      location,
      discountPercentage,
    });
  }, [exitIntent, pitch, location, discountPercentage]);

  const shouldUseInfluencerCode = !!influencerPromoCode;

  const handleAddUTMAndPromoCodeToUrl = () => {
    if (shouldUseInfluencerCode) return;
    addUTMAndPromoCodeToUrl(
      search_params,
      getUTMData(discountPercentage, isEmailPitch),
      promoCodeDetails.id
    );
  };

  const _triggerEmailTo = (email: string, templateName: string) => {
    triggerEmailTo(email, templateName, {
      shouldUseInfluencerCode,
      promoCodeDetails,
      discountPercentage,
      additionalPayload: {
        promo_code_id: promoCodeDetails.id,
      },
    });
  };

  const _submitEmail = (email: string) => {
    updateVisitorEmail(email);
    handleAddUTMAndPromoCodeToUrl();

    _triggerEmailTo(
      email,
      isDomainPitch ? "exit_intent_free_domain" : "exit_intent_with_discount"
    );
  };

  const _onContinueClick = () => {
    hideExitIntent();
    handleAddUTMAndPromoCodeToUrl();
  };

  const _onEvent = (eventName: string, extraPayload = {}) =>
    onEvent(
      eventName,
      { exitIntent, pitch, location, discountPercentage },
      extraPayload
    );

  if (!pitch || !exitIntent) return null;

  if (isEmailPitch) {
    return (
      <EmailPitchModal
        isOpen={!!exitIntent}
        onClose={hideExitIntent}
        onSubmit={_submitEmail}
        onEvent={_onEvent}
        discountPercentage={discountPercentage}
      />
    );
  } else if (isDomainPitch) {
    return (
      <DomainPitchModal
        isOpen={!!exitIntent}
        onClose={hideExitIntent}
        onSubmit={_submitEmail}
        onEvent={_onEvent}
        onContinueClick={_onContinueClick}
      />
    );
  }
  return null;
};

export default ExitIntent;
