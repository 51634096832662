// entri imports
import { checkDomain } from "entrijs";

import BACK_ICON from "../../assets/back.svg";
import LOGOUT_ICON from "../../assets/logoutLogo.svg";
import MAIL_ICON from "../../assets/mailLogo.svg";
import SETTING_ICON from "../../assets/settingLogo.svg";
// neo imports
import Button from "../../components/Common/Button/Button";
import CustomMobileSetupRenderer from "../../components/CustomMobileSetup/CustomMobileSetupRenderer";
import MobileStoreIconsRenderer from "../../components/MobileStoreIcons/MobileStoreIconsRender";
import {
  APP_STORE_CONSTANTS,
  LAMBDA_ENDPOINT,
  LAMBDA_PATH,
  MEDUSA_ENDPOINT,
  NEO_PARTNER_ID,
  NEO_PARTNER_ID_NUMBER,
} from "../../helpers/constants";
import { getStoreFromLocalStorage } from "../../helpers/store.helper";
import { showSuccessToast } from "../../helpers/toastHelper";
import { logoutUser } from "../../helpers/user";
import {
  getAdminPanelUrl,
  getConfig,
  getCopyToClipboard,
  getMailClientUrl,
  getNeoHomeUrl,
  getNeoSiteUrl,
  getWebmailUrl,
  redirectToNeoHome,
} from "../../helpers/utils";
import { logEventWithKeys } from "../../telemetry/medusaEventsFunctions";
import { ANALYTICS_ATTRIBUTES } from "../../telemetry/medusaHelper";
import { getDeviceInfo } from "../../telemetry/medusaService";
import { getEntriToken } from "./services/lambda/getEntriToken/service";
import { entriDomainChecked } from "./services/medusa/entriDomainChecked";
import {
  ENTRI_FLOW_ROUTES,
  ENTRI_SETUP_MODE,
  ENTRI_SETUP_TYPE,
  ENV_PROPS,
  FLOW_TYPE,
  PURCHASE_FLOW_CUSTOMER_SIGN_OUT_ATTRB,
  PURCHASE_FLOW_MEDUSA_ATTRB,
} from "./utils/const";
import { entri } from "./utils/entri";
import { errorLogger } from "./utils/error";
import { getSupportedQueryParams, logger } from "./utils/helpers";
import { getUrl } from "./utils/url";
import {
  selectMailAdminMailbox,
  selectMailDomain,
  selectMailPurchaseDomain,
} from "../../store/slices/mail.slice";
import Entri from ".";

// purpose of this module is avoid random imports which make application
// tightly coupled, here we know only one place all imports are happening
// just import this ENBridge and access the prop which we want to
const ENBridge = {
  // place for all sharable components
  sharedComponents: {
    Button,
    CustomMobileSetupRenderer,
    MobileStoreIconsRenderer,
    EntriRoot: Entri,
  },

  // place for sharable routes
  routes: {
    entriRoot: ENTRI_FLOW_ROUTES.ROOT,
  },

  // images/icons goes here
  images: {
    LOGOUT_ICON,
    MAIL_ICON,
    SETTING_ICON,
    BACK_ICON,
  },

  // sharable const
  const: {
    setupType: ENTRI_SETUP_TYPE,
    appStoreConst: APP_STORE_CONSTANTS,
  },

  // sharable functions (it can be anything util, service, etc)
  handlers: {
    getWebmailUrl,
    getNeoSiteUrl,
    getCopyToClipboard,
    showSuccessToast,
    getDeviceInfo,
    logoutUser,
    getEnvProps: () => {
      const { env } = getConfig();
      return ENV_PROPS[env];
    },
    redirectToCP: (cpToken, domain, params) => {
      const { sourceHook = "" } = getSupportedQueryParams();
      const cpAutoLoginURL = ENBridge.handlers.getAdminPanelUrl(
        cpToken,
        domain,
        {
          ...(params || {}),
          ...(sourceHook ? { source: sourceHook } : {}),
        }
      );
      // TODO: remove this once QA done
      logger("cpAutoLoginURL", cpAutoLoginURL);
      window.location.href = cpAutoLoginURL;
      return true;
    },
    redirectToWebmail: (email, webmailAutoLoginToken) => {
      window.open(getMailClientUrl(email, webmailAutoLoginToken), "_blank");
      return true;
    },
    openSiteSetupKBArticle: () => {
      window.open(
        "https://support.neo.space/hc/en-us/sections/36751636830361-Setup-A-record-and-CNAME-record-for-your-custom-domain",
        "_blank"
      );
    },
    getAdminPanelUrl: (cpToken, domain, params) => {
      const getFormattedQueryString = (prepend = "") =>
        params ? `${prepend}${new URLSearchParams(params).toString()}` : "";

      return cpToken && domain
        ? `${getAdminPanelUrl()}/autoLogin?token=${cpToken}&domain=${domain}${getFormattedQueryString(
            "&"
          )}`
        : `${getAdminPanelUrl()}${getFormattedQueryString("/?")}`;
    },
    getMedusaEndPoint: () => {
      const { env } = getConfig();
      return `${MEDUSA_ENDPOINT[env]}addPreAuthEvent`;
    },
    getNeoHomeUrl: (params = {}) => getNeoHomeUrl(params, false),
    redirectToNeoHome: (params = {}) => redirectToNeoHome(params, false),
    getMailAutoLoginUrl: (email, webmailAutoLoginToken) => {
      return getMailClientUrl(email, webmailAutoLoginToken);
    },
    // since we pull lambda end point from neo-signup
    getLambdaEndPoint: () => {
      const { env } = getConfig();
      return `${LAMBDA_ENDPOINT[env]}${LAMBDA_PATH[env]}?entity=neo&env=${env}`;
    },
    // shared check domain helper
    checkDomain: async (domain) => {
      try {
        const { ENTRI_APP_ID } = ENBridge.handlers.getEnvProps();
        const response = await getEntriToken();
        const { auth_token: token } = response;
        const applicationId = ENTRI_APP_ID;
        const checkDomainResponse = await checkDomain(domain, {
          token,
          applicationId,
        });

        return checkDomainResponse;
      } catch (e) {
        return errorLogger.ENTRI_DOMAIN_CHECK_FAILED(e);
      }
    },
    logEntriDomainCheckedViaPurchase: (checkDomainResponse) => {
      // medusa event
      const neoCurrentState = ENBridge.handlers.getPurchaseFlowProps();
      entriDomainChecked(checkDomainResponse, {
        ...neoCurrentState,
        flowType: FLOW_TYPE.PURCHASE,
      });
    },
    // function to fetch the necessary attrb from neo-signup
    // as name suggest in case of purchase flow we make call to this function
    // we assume whatever details we have from neo-signup in valid
    // we hydrate the NeoContext based on these values
    getPurchaseFlowProps: () => {
      const purchaseFlowState = getStoreFromLocalStorage() || {};
      const hasDetails = Object.keys(purchaseFlowState).length;
      const { sourceHook } = getSupportedQueryParams();
      const { env } = getConfig();

      if (hasDetails) {
        const customer = purchaseFlowState.user;
        // TODO: for site
        const { offering } = selectMailDomain(purchaseFlowState);
        // TODO: for site
        const domain = selectMailPurchaseDomain(purchaseFlowState);
        // TODO: for site
        const adminMailbox = selectMailAdminMailbox(purchaseFlowState);

        if (!customer.token || !domain) return null;

        const essentialData = {
          name: customer.name,
          domain: domain,
          email: adminMailbox?.email,
          customerId: customer.customer_id,
          accountId: customer.account_id,
          orderId: customer.order_id,
          customerEmail: customer.alternateEmail,
          billingInformation: customer.billingInformation,
          plan: customer.plan,
          hasActiveOrders: customer.hasActiveOrders,
          cpToken: customer.token,
          tokenExpiry: customer.tokenExpiry,
          controlPanelAutoLoginToken: customer.controlPanelAutoLoginToken,
          webmailAutoLoginToken: customer.webmailAutoLoginToken,
          neoOffering: offering,
          showTeamMailboxPostDomainPurchase:
            customer.showTeamMailboxPostDomainPurchase,
          partnerAlphaNumId: NEO_PARTNER_ID[env],
          partnerId: NEO_PARTNER_ID_NUMBER[env],
          source_hook: sourceHook,
        };

        return essentialData;
      }

      return null;
    },
    getMedusaAttrKeys(attrList) {
      return Object.keys(ANALYTICS_ATTRIBUTES)
        .map((k) => {
          // attrs taken from spec and expecting other default attrb will be set by `logEventWithKeys`
          return attrList.indexOf(k) !== -1 ? ANALYTICS_ATTRIBUTES[k] : null;
        })
        .filter((e) => e);
    },
    // in case of purchase flow we use this medusa handler
    // here we pass some additional props (related to order/domain)
    logPurchaseFlowMedusaEvent: (eventName, attrs = {}) => {
      const keysForPurchaseFlowEvents = Object.keys(ANALYTICS_ATTRIBUTES)
        .map((k) => {
          // attrs taken from spec and expecting other default attrb will be set by `logEventWithKeys`
          return PURCHASE_FLOW_MEDUSA_ATTRB.indexOf(k) !== -1
            ? ANALYTICS_ATTRIBUTES[k]
            : null;
        })
        .filter((e) => e);

      const { getDnsSetupFor, getPurchaseFlowProps } = ENBridge.handlers;
      const { orderId } = getPurchaseFlowProps();

      return logEventWithKeys(eventName, keysForPurchaseFlowEvents, {
        linkToEntity: true,
        entityId: orderId,
        entityType: getDnsSetupFor() === "Site" ? "mail_order" : "site_order",
        product: "website",
        user_type: "Order",
        ...attrs,
      });
    },
    // customer sign out is an existing events
    // now this can be called via auto login flow too
    // as per neo implementation we expect data via redux state
    // which is not be available incase of auto login
    // hence this is custom handler
    logPurchaseFlowCustomerSignedOutEvent: (attrs) => {
      const keysForSignOutEvent = Object.keys(ANALYTICS_ATTRIBUTES)
        .map((k) => {
          // check neo `logCustomerEvent` handler
          return PURCHASE_FLOW_CUSTOMER_SIGN_OUT_ATTRB.indexOf(k) !== -1
            ? ANALYTICS_ATTRIBUTES[k]
            : null;
        })
        .filter((e) => e);

      return logEventWithKeys(
        "customer_account_signed_out",
        keysForSignOutEvent,
        attrs
      );
    },
    getDnsSetupForQueryParam() {
      const { setupFor } = getSupportedQueryParams();
      return setupFor;
    },
    isSiteSetupMode() {
      return (
        ENBridge.handlers.getDnsSetupForQueryParam() === ENTRI_SETUP_MODE.SITE
      );
    },
    getDnsSetupFor() {
      return ENBridge.handlers.isSiteSetupMode() ? "Site" : "Mail";
    },
    getDnsRecordsToSet() {
      if (ENBridge.handlers.isSiteSetupMode()) {
        return ["a", "cname"];
      }

      return ["mx", "spf", "dkim", "domainOwnershipTxt"];
    },
    getEntriModalCopy() {
      if (ENBridge.handlers.isSiteSetupMode()) {
        return {
          initialScreen: {
            title: "Connect your domain to Neo Site",
            subTitle: "You're a few steps away...",
            easy: {
              description:
                "No developer needed, automatically configure your domain settings to work with Neo",
            },
          },
          manuallyScreen: {
            stepByStepGuide:
              "Need help adding DNS entries? Follow our <link><b>step-by-step guide</b></link>.",
          },
        };
      }

      return {
        initialScreen: {
          title: "Connect your domain to set up email",
          easy: {
            description:
              "No developer needed, automatically configure your domain settings to work with Neo",
          },
        },
        manuallyScreen: {
          stepByStepGuide:
            "Need help adding DNS entries? Follow our <link><b>step-by-step guide</b></link>.",
        },
      };
    },
  },
};

export default ENBridge;
