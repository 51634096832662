import { TSiteResources } from "../../types/industry.type";
import { getIdb, removeIdb, setIdb } from "../idb.helper";

const CACHE_EXPIRY_TIME = 5 * 60 * 60 * 1000; // 5 hours in milliseconds
export const SITE_RESOURCES_IDB_KEY = "siteResources";

export const storeSiteResourcesInIDB = async (
  siteResources: TSiteResources
) => {
  try {
    await setIdb<TSiteResources>(SITE_RESOURCES_IDB_KEY, {
      ...siteResources,
      generatedAt: Date.now(),
    });
  } catch (error) {
    console.error("Error storing site resources in IDB", error);
  }
};

export const getSiteResourcesFromIDB =
  async (): Promise<TSiteResources | null> => {
    try {
      const cachedData = await getIdb<TSiteResources>(SITE_RESOURCES_IDB_KEY);
      if (!cachedData?.value) return null;

      const { generatedAt } = cachedData.value;
      if (generatedAt && Date.now() - generatedAt > CACHE_EXPIRY_TIME) {
        await removeIdb(SITE_RESOURCES_IDB_KEY); // Remove expired data
        return null;
      }

      return cachedData.value;
    } catch (error) {
      console.error("Error getting site resources from IDB", error);
    }
    return null;
  };
