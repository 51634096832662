import * as amplitude from "@amplitude/analytics-browser";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { hotjar } from "react-hotjar";
import { PRODUCT_TYPES } from "../constants/products.constants";
import apiManager from "../services/axiosConfig";
import {
  AMPLITUDE_API_KEYS,
  COOKIE_KEYS,
  HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION,
  SENTRY_DSN,
  SERVICE_TYPES,
} from "./constants";
import { getCookie, setCookie } from "./cookie.helper";
import { getProductFromUrlPath } from "./products.helper";
import { generateNewDeviceId, getAppVersion, getConfig } from "./utils";

const { env } = getConfig();
let deviceId = getCookie(COOKIE_KEYS.DEVICE_ID);

if (!deviceId) {
  deviceId = generateNewDeviceId();
  setCookie(COOKIE_KEYS.DEVICE_ID, deviceId);
}

export const initApiServices = () => {
  apiManager.createApiService({ type: SERVICE_TYPES.LAMBDA[env] });
  apiManager.createApiService({ type: SERVICE_TYPES.MEDUSA[env] });
  apiManager.createApiService({ type: SERVICE_TYPES.BLL[env] });
};

export const initAmplitude = () => {
  // initialize Amplitude

  amplitude.init(AMPLITUDE_API_KEYS[env], {
    defaultTracking: {
      attribution: true,
      pageViews: false,
    },
  });

  window.amplitude = amplitude;

  // set device ID and call amplitude's Identify call
  const identifyObj = new amplitude.Identify();
  amplitude.setDeviceId(deviceId);
  amplitude.identify(identifyObj);
};

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
  Sentry.setTag("app_version", getAppVersion());
  Sentry.setTag("device_id", deviceId);
};

export const initHotjar = () => {
  const productType = getProductFromUrlPath(window.location.pathname);
  if (productType === PRODUCT_TYPES.SITE) {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
  }
};
