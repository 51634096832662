import { REFERRAL_ACTIONS } from "../constants/referral.constant";
import { TReferralDetails } from "../types/referral.type";
import {
  getQueryParamFromSearch,
  removeQueryParamsFromSearch,
} from "../utils/urlUtils";
import { addQueryParamsInCurrentUrlAndStore } from "./urlHelpers";
import { cleanUrl } from "./utils";

export const cleanAndRemoveQueryParam = (
  key: string[],
  search_params: string
): string => {
  cleanUrl([key]);
  return addQueryParamsInCurrentUrlAndStore(
    removeQueryParamsFromSearch(search_params, key)
  );
};

export const isReferralFlow = (search_params: string): boolean => {
  const action = getQueryParamFromSearch(search_params, "action");
  return action === REFERRAL_ACTIONS.OPEN_REFERRAL;
};

export const getInviterCelloUCC = (search_params: string): string | null => {
  return getQueryParamFromSearch(search_params, "ucc");
};


/**
 * @desc Checks if the referral benefit is used or not based on the userState.
 * @param {number} userState - The user state which indicates whether the referral benefit is used.
 * @param {TReferralDetails | null} referralDetails - The referral details object.
 * @returns {boolean} - Returns true if the referral benefit is used, otherwise false.
 */
export const isReferralBenefitUsed = (userState: number, referralDetails: TReferralDetails): boolean => {
  // If referralDetails is missing (null or undefined), consider the benefit as used
  if (!referralDetails) {
    return true;
  }

  // If the second bit of userState is set, return true (USED), otherwise false (NOT USED)
  return (userState & (1 << 1)) !== 0;
};