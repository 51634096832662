import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { promoSelector } from "../../store/selectors/promoSelector";
import { getPromoCodeIdFromURL, getUrlParams } from "../../helpers/utils";
import { fetchPromoDetails } from "../../store/slices/promo.slice";
import {
  getInfluencerPromoCodeFromUrl,
  removeInfluencerPromoCodeFromUrl,
  removePromoFromUrl,
} from "../../helpers/promo.helper";
import { addQueryParamsInCurrentUrlAndStore } from "../../helpers/urlHelpers";
import useIsLoggedIn from "../useIsLoggedIn";

const usePromo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const promo = useSelector(promoSelector);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (promo?.promoCodeDetails || promo?.promoCodeErrorDetails) {
      return;
    }

    const influencerPromoCode = getInfluencerPromoCodeFromUrl();
    const promoCodeId = getPromoCodeIdFromURL();

    if (influencerPromoCode) {
      fetchInfluencerPromoUsingPromoCode(influencerPromoCode);
      if (getUrlParams().utm_source === "forneo") {
        // Update query param based on utm_source
        addQueryParamsInCurrentUrlAndStore({ promo_code: influencerPromoCode });
      }
    } else if (promoCodeId) {
      fetchPromoUsingId(promoCodeId);
    }
  }, [promo?.promoCodeDetails, promo?.promoCodeErrorDetails, isLoggedIn]);

  const fetchPromoUsingId = async (promoCodeId: string) => {
    setIsLoading(true);
    try {
      await dispatch(
        fetchPromoDetails({
          payload: { promoCodeId },
        })
      );
    } catch (error) {
      removePromoFromUrl();
    }
    setIsLoading(false);
  };

  const fetchInfluencerPromoUsingPromoCode = async (promoCode: string) => {
    setIsLoading(true);
    try {
      await dispatch(
        fetchPromoDetails({
          payload: { promoCode },
          flags: { influencerPromoCode: promoCode },
        })
      );
    } catch (error) {
      removeInfluencerPromoCodeFromUrl();
    }
    setIsLoading(false);
  };

  return {
    isLoading,
  };
};

export default usePromo;
