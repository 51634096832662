import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  generateCelloToken,
  getCelloRewardDetails,
} from "../../services/referral/referral.service";
import {
  TCelloTokenResponse,
  TGenerateCelloTokenPayload,
} from "../../types/referral.type";
import { RootState } from "../../types/store.type";

// Initial state for the slice
const initialState = {
  referralRewardDetails: null,
};

// Create the slice
const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    setReferralRewardDetails: (state, action: PayloadAction<any>) => {
      state.referralRewardDetails = action.payload;
    },
  },
});

// **Thunk to generate Cello jwt**
export const generateCelloTokenThunk = createAsyncThunk<
  TCelloTokenResponse,
  TGenerateCelloTokenPayload,
  { rejectValue: string }
>("cellotoken/generate", async (payload, { rejectWithValue }) => {
  try {
    const response = await generateCelloToken(payload);
    if (response.status !== 200) {
      return rejectWithValue(response.data || "Failed to generate Cello token");
    }
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Thunk to fetch Cello reward details and directly dispatch to the store
export const getRewardDetailsThunk = createAsyncThunk(
  "get/fetchCelloRewardDetails",
  async (_, { dispatch, getState }) => {
    const referralRewardDetails = selectReferralRewardDetails(
      getState() as RootState
    );

    // Check if data already exists in store
    if (referralRewardDetails) {
      // Data already exists, so do not make the API call
      return;
    }
    try {
      const response = await getCelloRewardDetails();

      if (response.status !== 200) {
        throw new Error("Failed to fetch Cello reward details");
      }

      dispatch(setReferralRewardDetails(response.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const selectReferralRewardDetails = (state: RootState) =>
  state.referral.referralRewardDetails;

export const { setReferralRewardDetails } = referralSlice.actions;

export default referralSlice.reducer;
