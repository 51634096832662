import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { AppDispatch } from "../store/store";
import { PRODUCT_TYPES } from "../constants/products.constants";
import { OFFERING, PAGE_PATHS } from "../helpers/constants";

import {
  selectMailPurchaseDomain,
  setMailDomain,
} from "../store/slices/mail.slice";
import {
  selectSitePurchaseDomain,
  setSiteDomain,
} from "../store/slices/site.slice";
import MailNeoLogo from "../components/NeoLogo/Mail";
import SiteNeoLogo from "../components/NeoLogo/Site";
import { getSignUpAppUrl } from "../helpers/utils";
import { getProductFromUrlPath } from "../helpers/products.helper";

// Custom hook to manage product-specific handlers
export const useProduct = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const productType = getProductFromUrlPath(location.pathname);
  const mailDomain = useSelector(selectMailPurchaseDomain);
  const siteDomain = useSelector(selectSitePurchaseDomain);

  const productHandlers = useMemo(
    () => ({
      [PRODUCT_TYPES.MAIL]: {
        signupPath: PAGE_PATHS.SIGN_UP,
        getDomainPath: PAGE_PATHS.GET_DOMAIN,
        haveDomainPath: PAGE_PATHS.HAVE_DOMAIN,
        getStartedPath: PAGE_PATHS.GET_STARTED,
        setDomain: (domain: string, isCoSite: boolean) =>
          dispatch(
            setMailDomain({
              [isCoSite ? "coSiteDomain" : "customDomain"]: domain,
              offering: isCoSite ? OFFERING.COSITE : OFFERING.CUSTOM_DOMAIN,
            })
          ),

        logo: MailNeoLogo,
        navigateToSignIn: (searchParams: string) => {
          window.location.href = getSignUpAppUrl(
            PAGE_PATHS.SIGN_IN,
            searchParams
          );
        },
        domain: mailDomain,
        fallbackRouteForUnauthenticatedUser: PAGE_PATHS.SIGN_UP,
        productType: PRODUCT_TYPES.MAIL,
      },
      [PRODUCT_TYPES.SITE]: {
        signupPath: PAGE_PATHS.SITE_SIGN_UP,
        getDomainPath: PAGE_PATHS.SITE_GET_DOMAIN,
        haveDomainPath: PAGE_PATHS.SITE_HAVE_DOMAIN,
        getStartedPath: PAGE_PATHS.SITE_DOMAIN_SETUP,
        setDomain: (domain: string, isCoSite: boolean) =>
          dispatch(
            setSiteDomain({
              [isCoSite ? "coSiteDomain" : "customDomain"]: domain,
              offering: isCoSite ? OFFERING.COSITE : OFFERING.CUSTOM_DOMAIN,
            })
          ),

        logo: SiteNeoLogo,
        navigateToSignIn: (searchParams: string) => {
          window.location.href = getSignUpAppUrl(
            PAGE_PATHS.SITE_SIGN_IN,
            searchParams
          );
        },
        domain: siteDomain,
        fallbackRouteForUnauthenticatedUser: PAGE_PATHS.SITE_SIGN_UP,
        productType: PRODUCT_TYPES.SITE,
      },
    }),
    [dispatch]
  );

  return productHandlers[productType];
};
