import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getUnauthSiteResources,
  saveSiteVisitorData,
} from "../../services/visitor/visitor.service";
import { RootState } from "../../types/store.type";
import { userSelector } from "../selectors";
import { startSiteGeneration } from "../../helpers/site/site-offering.helper";
import {
  TBusinessProfile,
  TSiteGenerationParams,
} from "../../types/site-offering.type";
import { getUrlParams } from "../../helpers/utils";
import { createBitMask } from "../../utils/bitmask.util";
import {
  getSiteResourcesFromIDB,
  storeSiteResourcesInIDB,
} from "../../helpers/site/resources.helper";
import { TSiteResourcesPayload } from "../../types/site-resources.type";
import { SITE_PROMO_CODE_DETAILS } from "../../constants/exitIntent.constants";
import { selectSitePromoCodeDetailsForExitIntent } from "./site.slice";
import { transferSiteVisitorData } from "./../../services/visitor/visitor.service";

interface VisitorDataState {
  visitorEmail: string;
  site: {
    businessProfile: {
      description: string;
      industry: string;
    };
  };
}

const initialState: VisitorDataState = {
  visitorEmail: "",
  site: {
    businessProfile: {
      description: "",
      industry: "",
    },
  },
};

const visitorDataSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    saveBusinessDescriptionAction: (state, action: PayloadAction<string>) => {
      state.site.businessProfile.description = action.payload;
    },
    saveBusinessIndustryAction: (state, action: PayloadAction<string>) => {
      state.site.businessProfile.industry = action.payload;
    },
    resetVisitorDataAction: () => {
      return initialState;
    },
  },
});

// Export actions for use in components
export const {
  saveBusinessDescriptionAction,
  saveBusinessIndustryAction,
  resetVisitorDataAction,
} = visitorDataSlice.actions;

// Selectors
export const businessIdeaSelector = (state: { visitor: VisitorDataState }) =>
  state.visitor.site.businessProfile.description;
export const businessIndustrySelector = (state: {
  visitor: VisitorDataState;
}) => state.visitor.site.businessProfile.industry;

// Export the reducer to be added to the store
export default visitorDataSlice.reducer;

export const startVisitorSiteGenerationAction = createAsyncThunk(
  "visitor/startSiteGeneration",
  async (payload: TSiteGenerationParams, { getState }) => {
    const {
      visitorEmail,
      description,
      industryKey,
      industryName,
      parentIndustryKey,
    } = payload;

    const user = userSelector(getState() as RootState);
    const promoDetails =
      selectSitePromoCodeDetailsForExitIntent(getState() as RootState) ||
      SITE_PROMO_CODE_DETAILS;
    const customerId = user?.customer_id;
    const userType = user?.token ? "Customer" : "Visitor";
    const userInfo = {
      email: visitorEmail,
      customerId,
      userType,
    };

    const params: TBusinessProfile = industryKey
      ? {
          description,
          industryKey,
          ...(parentIndustryKey && { parentIndustryKey }),
        }
      : { description, industryName: industryName! };

    return startSiteGeneration(params, userInfo, promoDetails);
  }
);

export const transferSiteVisitorDataAction = createAsyncThunk(
  "visitor/transferSiteVisitorData",
  async (payload: Record<string, any>, { getState }) => {
    try {
      const { token } = userSelector(getState() as RootState);
      if (!token) {
        throw new Error("User is not logged in");
      }
      await transferSiteVisitorData({ token, ...payload });
      return;
    } catch (error) {
      return error;
    }
  }
);

export const getUnauthSiteResourcesDataAction = createAsyncThunk(
  "visitor/getUnauthSiteResourcesData",
  async (payload: TSiteResourcesPayload, { rejectWithValue }) => {
    try {
      // Use cached data from IDB if available
      const cachedData = await getSiteResourcesFromIDB();
      if (cachedData?.resources?.length) {
        return cachedData.resources;
      }

      // Fetch from API (Retries are handled in the API manager)
      const response = await getUnauthSiteResources(payload);

      // Store data in IDB
      if (response?.data?.resources?.length) {
        await storeSiteResourcesInIDB({ resources: response.data.resources });
      }

      return response.data.resources;
    } catch (error) {
      return rejectWithValue("Failed to fetch site resources.");
    }
  }
);

export const transferSiteVisitorDataPostAuthAction = createAsyncThunk(
  "visitor/transferSiteVisitorDataPostAuth",
  async ({ visitorEmail }: { visitorEmail: string }, { getState }) => {
    try {
      const storeState = getState() as RootState;
      const bn = getUrlParams().bn;
      const bd = getUrlParams().bd || businessIdeaSelector(storeState);
      if (!bn || !bd) {
        return;
      }
      const payload = {
        ve: visitorEmail,
        d: {
          bn,
          bd,
          s: createBitMask(["hasUsedAiFlow"])({
            hasUsedAiFlow: !!getUrlParams().hasUsedAiFlow,
          }),
          d: {
            template_key: getUrlParams().templateKey,
            industry_key: getUrlParams().industryKey,
            // sck: getUrlParams().siteCategory,
          },
        },
      };
      return await saveSiteVisitorData(payload);
    } catch (error) {
      return error;
    }
  }
);
