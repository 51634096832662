import { LS_REWARD_MODAL } from "../constants/referral.constant";
import { updateLogout } from "../store/actions/logoutActions";
import { store } from "../store/store";
import { COOKIE_KEYS } from "./constants";
import { eraseCookie } from "./cookie.helper";
import { deleteItemFromLocalStorage } from "./local-storage.helper";

const { CUSTOMER_ID_COOKIE } = COOKIE_KEYS;

export const logoutUser = async () => {
    store.dispatch(updateLogout());

  eraseCookie(CUSTOMER_ID_COOKIE);
  deleteItemFromLocalStorage(LS_REWARD_MODAL);
  };